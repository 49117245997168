$side-width: 480px;

.trade-party-title {
  font-size: 30px;
  font-weight: 700;
  position: relative;
  top: -10px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.complete-modal {
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    background: #121c2f;
    padding: 20px;
    border-radius: $box-radius;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: auto 40px;
    max-width: 600px;
    text-align: center;

    img {
      width: 80px;
      margin: 20px 0;
    }

    h2 {
      font-weight: 700;
      font-size: 22px;
      margin: 20px 0 10px;
    }

    p {
      margin: 20px 0;
    }

    .button {
      margin: 20px 0;
      width: 100%;
      max-width: 200px;
    }
  }
}

.participants-list {
  ul {
    list-style: none;

    li {
      background: #10263a;
      margin-bottom: 15px;
      padding: 12px 14px;
      border-radius: $box-radius;
      min-height: 72px;
      font-weight: 700;
      font-size: 17px;
      display: flex;
      align-items: center;

      .chakra-avatar {
        margin-right: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .party-count {
        margin-left: auto;
        font-size: 14px;
        opacity: 0.5;
      }
    }
  }
}

.trade-details {
  font-size: 15px;
  line-height: 1.6;

  h3 {
    font-weight: 700;
    font-size: 19px;
    margin: 25px 0;

    .tag {
      position: relative;
      top: -2px;
      left: 10px;
    }
  }

  ul {
    list-style: none;

    li {
      height: 30px;

      &.chain {
        text-transform: capitalize;
      }

      span {
        font-weight: 700;
        min-width: 70px;
        display: inline-block;
      }
    }
  }

  .description {
    margin: 30px 0;
  }
}

.trade-gif {
  border-radius: $box-radius;
  width: 100%;
  background: #052032;
  margin-bottom: 25px;
  position: relative;

  canvas {
    border-radius: $box-radius;
    width: 100% !important;
    height: auto !important;
  }

  .trade-vis-wrapper {
    .vis-btn {
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 15px;
      color: #50648d;

      svg {
        width: 17px;
        height: 17px;
      }

      &.zoom-btn {
        right: 50px;
      }

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }

    &.zoomed {
      position: fixed;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      background: rgba(0, 0, 0, 0.9);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 9999999 !important;
      padding: 30px;
      overflow: hidden;
      overflow-y: auto;

      .trade-vis {
        max-width: 1000px;
        margin: auto;
        width: 100%;
      }

      .vis-btn {
        position: fixed;
        top: 20px;

        &.zoom-btn {
          right: 20px;
        }

        &.download-btn {
          right: 70px;
        }

        svg {
          width: 25px;
          height: 25px;
          fill: #fff;
          color: #fff;
        }
      }
    }
  }

  img {
    border-radius: $box-radius;
    width: 100%;
    height: 100%;
  }
}

.trade-result {
  display: flex;
  width: 100%;

  .col {
    padding: 30px;
  }

  .trade-side {
    border-radius: $box-radius 0 0 $box-radius;
    min-width: $side-width;
    max-width: $side-width;
  }

  .trade-content {
    flex-grow: 1;
    border-radius: 0 0 $box-radius 0;
  }

  .trade-top-bar {
    padding: 0 0 25px 0;

    h3 {
      font-weight: 700;
      font-size: 21px;
    }
  }

  .trade-items-div {
    background: rgb(102 100 100 / 13%);
    padding: 25px;
    border-radius: $box-radius;
    margin-bottom: 25px;
    min-height: 350px;
  }

  .feedback {
    margin-top: 4rem;

    p {
      text-align: center;
      font-size: 0.9rem;
    }
  }

  .happy {
    :hover {
      box-shadow: 40px 40px 40px;
      font-size: 5rem;
    }
  }

  .feedback-div {
    margin-top: 0.5rem;
    align-items: center;
    display: flex;
    width: fit-content;
    gap: 10px;
    margin-right: auto;
    margin-left: auto;

    :hover {
      box-shadow: 10px;
      cursor: pointer;
      text-shadow: 40px 40px;
    }
  }

  .rating-icon:hover {
    transform: translateY(-2px);
    width: fit-content;
    padding: 0;
  }

  .green:hover {
    filter: drop-shadow(0px 0px 18px #3cfd96);
  }

  .yellow:hover {
    filter: drop-shadow(0px 0px 18px yellow);
  }

  .brown:hover {
    filter: drop-shadow(0px 0px 18px rgb(124, 26, 26));
  }

  .red:hover {
    filter: drop-shadow(0px 0px 18px red);
  }

  .feedback-icons {
    display: flex;
  }

  .trade-items {
    display: flex;

    h4 {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 17px;
      display: flex;
      align-items: center;

      svg {
        margin-left: 10px;
        width: 20px;
        height: 20px;
        color: #fff;
      }
    }

    $split: 20px;

    .trade-give {
      width: 50%;
      margin-right: $split;
      display: flex;
      flex-direction: column;

      .trade-results {
        .result-item {
          font-size: 15px;
        }
      }
    }

    .trade-get {
      margin-left: $split;
      width: 50%;
      position: relative;
      display: flex;
      flex-direction: column;

      h4 {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .trade-notice {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      h3 {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 5px;
      }

      .icon {
        width: 70px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        border-radius: 50%;
        position: relative;
        top: -10px;

        svg {
          height: 25px;
          width: 25px;
        }
      }
    }
  }

  .trade-results {
    width: 100%;
    border-radius: $box-radius;
    flex-grow: 1;
    align-content: flex-start;

    .result-item {
      border-radius: 7px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #021520;
      aspect-ratio: 1;
      font-size: 20px;
      font-weight: 700;
      border: 1px solid #101823;
      text-align: center;

      img {
        min-width: 100%;
        max-width: 100%;
        height: auto;
      }
    }
  }
}

@media only screen and (max-width: 1354px) {
  .trade-result {
    flex-direction: column;

    .trade-side {
      flex-direction: row;
      display: flex;
      width: 100%;
      max-width: unset;
      order: 2;
      margin-bottom: 40px;

      .trade-gif {
        max-width: 400px;
        margin-bottom: 0;
      }

      .trade-details {
        margin-left: 30px;

        h3 {
          margin-top: 0;
        }
      }
    }

    .trade-items {
      min-height: unset;
    }
  }
}

@media only screen and (max-width: 819px) {
  .trade-result {
    .trade-items {
      .trade-results {
        grid-template-columns: 1fr 1fr !important;
      }
    }
  }
}

@media only screen and (max-width: 636px) {
  .trade-result {
    .trade-side {
      flex-direction: column;
      align-items: center;
      min-width: unset;
      padding: 30px 0 0;
    }
  }
}

@media only screen and (max-width: 636px) {
  .trade-result {
    .trade-items {
      flex-direction: column;

      .trade-give {
        margin-bottom: 20px;
      }

      .trade-get,
      .trade-give {
        margin-left: 0;
        width: 100%;
      }
    }
  }
}