.filters {
  margin: 25px 0 20px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1741px) {
    flex-wrap: wrap;
    gap: 13px;
    justify-content: flex-end;
  }

  .layout-toggle {
    display: flex;
    align-items: center;
  }

  .group-switch {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 170px;
    margin-right: auto;
    cursor: pointer;
  }

  .filter-input {
    margin: 0 10px;
    flex: 1;
    @media screen and (max-width: 1741px) {
      margin: 0px;
    }

    .group-switch {
      display: flex;
      flex-direction: row-reverse;
      gap: 2px;
      align-items: center;

      justify-content: start;
      width: 100%;
      max-width: 150px;

      cursor: pointer;
    }

    .filter-input {
      margin: 0 10px;

      &.sort {
        max-width: fit-content;

        font-size: 0.8rem;
        font-weight: 800;
      }

      &.collection {
        max-width: fit-content;

        font-size: 0.8rem;
        font-weight: 800;
      }
    }
  }
}
