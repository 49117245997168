.page-width {
    width: 100%;
    margin: 0 auto;
    max-width: 1300px;
 }

#page {
    padding-top: 126px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .page-content {
        flex-grow: 1;
    }

    .page-title {
        padding: 6px 0 0 0;
        margin-bottom: 40px;
    }
}