.link-a {
    color: #FC822F;
    font-weight: 100;

}

.desc {
    margin-bottom: 20px;
}


.check-allowlist {
    svg {
        font-size: 30px;
        margin-right: 5px;
    }

    background: #b87124;
    padding: 9px 9px 7px;
    font-size: 14px;
    border-radius: 4px;
    color: #fff;
    margin-top: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

}