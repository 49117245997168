.tag {
    background: #39cd60;
    font-size: 9px;
    padding: 2px 7px;
    display: inline-flex;
    vertical-align: middle;
    color: #052032;
    align-items: center;
    border-radius: 50px;
    text-transform: uppercase;

    &.orange {
        background: orange;
    }

    &.dark {
        background: #123e5c;
        color: #fff;
    }
}