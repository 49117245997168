#footer {
    display: flex;
     align-items: center;
    justify-content: center;
    color: var(--default-color);
 
    background-image: url(../../assets/footer.png);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
 
    .sep {
        display: inline-block;
        margin: 0 10px;
    }
}