.bidding-grid-item {
  .list-controls {
    display: flex !important;
  }

  .contents {
    .chakra-aspect-ratio {
      &.hasbid {
        border: 3px solid #fc822f;
      }
    }
  }

  .grid-item-footer {
    .reserve-label {
      margin: 0;
    }

    .reserve-price {
      font-size: 14px;
      margin-bottom: 6px;
    }

    .chakra-input__right-addon {
      padding: 0 !important;
    }
  }
}

.hide-sm {
  @media screen and (max-width: 600px) {
    display: none;
  }
}

#chakra-modal--body-listing-modal {
  padding: 0;
}

#chakra-modal--header-listing-modal {
  padding: 17px 16px 10px;
}

.no-bids {
  padding: 70px 40px;
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
}

.bidding-modal.tabs-wrapper {
  width: 70%;
  min-height: 460px;
  margin-left: 15px;
}

.bid-item {
  background: rgb(102 100 100 / 13%);
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-right: 5px;
  filter: drop-shadow(0px 10px 53px rgba(243, 220, 126, 0.3));

  @media screen and (max-width: 468px) {
    padding: 5px;
  }

  .row-button-holder {
    margin-left: auto;
  }

  .details-and-input-holder {
    display: flex;
    align-items: center;
    flex: 1;

    @media screen and (max-width: 468px) {
      flex-direction: column;
      align-items: flex-start;

      .bid-amount {
        margin-bottom: 5px;
      }
    }
  }

  .restore-btn {
    height: 28px;
    border: 1px solid #fc822f;
    border-radius: 6px;
  }

  .bid-amount {
    text-align: left;
    display: flex;
    align-items: center;
    margin-right: 10px;
    flex: 1;

    h3 {
      margin-right: 5px;
      font-size: 12px;
      min-width: 110px;
      cursor: pointer;
    }

    p {
      margin: 0px;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;

      &.reserver-label {
        font-weight: 700;
      }
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;

      .itemsep {
        display: none;
      }

      .reserve-price-holder {
        display: flex;
        align-items: center;

        .reserve-label {
          margin-left: 3px;
        }
      }
    }
  }

  .reserve-form-holder {
    @media screen and (max-width: 600px) {
      margin-left: auto;
    }

    @media screen and (max-width: 500px) {
      margin-left: 0px;
    }
  }

  .itemsep {
    width: 1px;
    height: 25px;
    background: #ffffff;
    margin-left: 8px;
    margin-right: 8px;
  }

  .image {
    margin-right: 10px;
  }

  .icon-button {
    width: 50px;
    height: 28px;
    background-color: transparent;
    border-radius: 0px;

    svg {
      width: 24px;
      height: 24px;
      opacity: 0.6;
    }
  }

  .remove-icon {
    margin-left: 0px;
  }
}

.reserve-form {
  display: flex;

  &.active {
    background: #fff;
  }

  .reserve-price-input {
    border-radius: 0px;
    height: 28px;
    background: #323e58;
    padding-left: 6px;
  }

  .reserve-price-disabled {
    border-radius: 0px;
    border: 1px solid #052032;
    height: 28px;
    width: 110px;
    box-sizing: border-box;
    padding: 4px 7px;
    font-size: 13px;
  }

  .stepper-controls {
    border: 0px;
  }

  .icon-button {
    &.active {
      background: #fff;
      border-radius: 0px;
    }
  }
}

.bidding-wrapper {
  display: flex;

  table {
    thead {
      tr {

        th,
        th[data-is-numeric="true"] {
          text-align: center;
          padding: 10px;
        }
      }
    }

    tbody {
      tr {

        td,
        td[data-is-numeric="true"] {
          text-align: center;
          font-size: 12px;
          padding: 10px;
        }
      }
    }
  }

  .grid-view {
    flex-grow: 1;
  }

  .manage-bids {
    width: 520px;
    margin-left: 30px;
    border-radius: 0px 0px 8px 8px;

    .tabs-wrapper {
      height: 100%;

      .tabs-wrapper {
        height: 100%;

        svg {
          margin-top: 10px;
        }
      }

      .update-status {
        text-align: right;
        padding-top: 2px;
        margin-bottom: 27px;
      }
    }

    .no-bids-message {
      background: rgb(102 100 100 / 13%);
      padding: 80px 20px;
      text-align: center;
    }

    .tabs-buttons {
      margin-bottom: -1px;

      .tab {
        min-width: 100px;
        padding: 10px 15px;
        font-weight: bold;
        font-size: 17px;
        letter-spacing: 0.05em;

        &.active {
          background: #FF4ADA;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          border-bottom: 1px solid #FF4ADA;
        }
      }
    }
  }
}

#Trynow {
  background: $neo-gradiant;

  // margin-top: 14px;
  :hover {
    background: #484848;
  }
}

.modal-footer {
  background-color: #091824;
}

.bidding-modal {
  .listing-modal-details {
    min-height: 430px;
  }
}

.top-bids {
  p {
    font-size: 14px;
    margin-bottom: 20px;
  }

  ul {
    list-style: none;

    li {
      background: #2d384d;
      padding: 8px 8px 7px 12px;
      margin: 8px 0;

      .number-label {
        display: inline-block;
        margin-right: 10px;
      }

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}

.modal-bid-info {
  background: #484848;
  padding: 10px 10px;
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto 0 20px;

  &.winning {
    background: #3e9320;
  }

  &.tied {
    background: #e0a41f;
    color: #000 !important;
  }
}

.icons-circle {
  align-items: center;
  gap: 5px;
}

.ns-status-tag {
  padding: 7px 13px;
  display: flex;
  border-radius: 10rem;
  justify-content: center;
  width: fit-content;
  font-size: 0.85rem;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 5px;

  &.winning {
    span {
      display: block;
      width: 12px;
      height: 12px;
      background: #40cd56;
      border-radius: 50%;
    }
  }

  &.losing {
    span {
      display: block;
      width: 12px;
      height: 12px;
      background: #cd4840;
      border-radius: 50%;
    }
  }
}

.details {

  .high-bid,
  .status-tag {
    background: rgba(41, 41, 41, 0.308);
    border: 1px solid rgba(41, 41, 41, 0.308);
    position: absolute;
    top: 16px;
    left: 16px;
    padding: 3px 10px;
    display: flex;
    border-radius: 10rem;

    justify-content: center;
    width: fit-content;
    //margin: 0;
    font-size: 0.85rem;

    &.winning {
      background: rgba(41, 41, 41, 0.308);
      border: 1px solid rgba(41, 41, 41, 0.308);
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 4px 8px;

      span {
        display: block;
        width: 12px;
        height: 12px;
        background: #40cd56;
        border-radius: 50%;
      }
    }
  }

  #input-color {
    border: #fc822f;
  }

  .tie-bid {
    background: #1e4057;
    position: absolute;
    top: 15px;
    left: 6%;
    padding: 3px 10px;
    display: flex;
    flex-direction: row;
    border-radius: 10rem;
    align-items: center;
    justify-content: start;
    width: fit-content;

    //margin: 0;
    font-size: 0.85rem;
    gap: 2px;

    &.winning {
      background: #1e4057;
      margin-left: auto;
    }

    &.tied {
      background: #1e4057;
    }
  }
}

.sidebar-status-tag {
  background: #1e4057;
  padding: 8px 12px;
  display: flex;
  border-radius: 10rem;

  justify-content: center;
  width: fit-content;
  //margin: 0;
  font-size: 0.85rem;
  margin-bottom: 20px;

  &.winning {
    background: #1e4057;
    display: flex;
    align-items: center;
    gap: 5px;

    span {
      display: block;
      width: 12px;
      height: 12px;
      background: #40cd56;
      border-radius: 50%;
    }
  }

  &.losing {
    background: #1e4057;
    display: flex;
    align-items: center;
    gap: 5px;

    span {
      display: block;
      width: 12px;
      height: 12px;
      background: #cd4840;
      border-radius: 50%;
    }
  }
}

.silent-auction-message {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  h3 {
    font-size: 21px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    opacity: 70%;
  }

  &~.spending-limit {
    width: 100%;
  }
}

.spending-limit-wrapper {
  margin-right: 27px;
  display: flex;

  .limit-toggle {
    align-items: center;

    label {
      margin-bottom: 0px;
    }
  }
}

.nft-limit {
  margin-top: 0px;
  margin-left: 12px;

  input {
    border-radius: 0;
    background: transparent;
  }
}

.custom-number-input {
  .borderless-conrols {
    border: 0px;
  }

  .chakra-numberinput,
  input {
    height: 32px;
  }

  .custom-number-input-right-addon {
    border: 0px;
    height: 32px;
  }
}

.spending-limit {
  display: flex;
  align-items: center;

  .set-limit-form {
    width: inherit;
  }

  input {
    border-radius: 0;
  }

  .set-max-btn {
    border: 1px solid #FF4ADA;
    background-color: #FF4ADA;
    border-radius: 6px;
    color: #f8f8f8;
    padding: 3px 24px;
    height: unset;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .save-wallet-btn {
    border: 1px solid #FF4ADA;
    background-color: #FF4ADA;
    border-radius: 6px;
    color: #f8f8f8;
    padding: 6px 24px;
    height: unset;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .spending-limit-input {
    height: 32px;
  }

  .stepper-controls {
    border: none;
    align-items: flex-start;

    &:first-child {
      align-items: flex-end;
    }
  }
}

.btndiv {
  display: flex;
  justify-content: space-between;
}

.what-btn {
  color: #fc822f;
  text-decoration: underline;
  font-weight: 700;
}

.room-title {

  .room-title-header {
    font-weight: bold;
    font-size: 1.3rem;
  }

  .room-title-text {
    font-size: 1rem;
    font-weight: 100;
    font-family: Arial, Helvetica, sans-serif;
  }
}

.modal {
  .head {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 300;
  }

  .body {
    margin-top: 0.5rem;
    font-weight: 100;
    font-size: 0.8rem;
  }
}

.text-sm-important {
  font-size: 0.875rem !important;
}

.budget-area {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  position: relative;
  overflow: hidden;
  -webkit-padding-start: var(--chakra-space-4);
  padding-inline-start: var(--chakra-space-4);
  -webkit-padding-end: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  padding-top: var(--chakra-space-3);
  padding-bottom: var(--chakra-space-3);
  --alert-fg: var(--chakra-colors-blue-500);
  --alert-bg: var(--chakra-colors-blue-100);



}

.listing-area {
  margin-bottom: 10px;


  .listing-page {
    flex-grow: 1;
  }
}

.page-header {
  .small-link {
    color: #fc822f;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 1579px) {
  .available-nfts {
    .check-bids-message {
      display: none;
    }
  }
}

@media only screen and (max-width: 939px) {
  .bidding-wrapper {
    flex-direction: column-reverse;

    .manage-bids {
      max-width: 100%;
      width: 100%;
      margin-left: 0;
    }
  }
}

@media only screen and (max-width: 831px) {
  .listing-area {
    margin-top: 30px;
    flex-direction: column;

    .listing-page {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  .silent-auction-message {
    margin: 0 0 40px;
  }
}

@media only screen and (max-width: 504px) {
  .bidding-header {
    flex-direction: column-reverse !important;

    &>.chakra-stack {
      width: 100%;
      margin-bottom: 40px;
      text-align: center;
      margin-left: 0;

      h2,
      h4 {
        width: 100%;
      }
    }
  }
}

.bid-remove-toolip {
  position: absolute;
  bottom: 15px;
  left: 15px;
}