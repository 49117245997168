@font-face {
  font-family: 'InterRegular';
  src: url('../../public/fonts/Inter/Inter-Regular.ttf'),
    url('../../public/fonts/Inter/Inter-Regular.ttf');
}

 
@font-face {
  font-family: 'ArchivoBlack';
  src: url('../../public/fonts/ArchivoBlack/ArchivoBlack-Regular.ttf');
}
 
@font-face {
  font-family: 'InterBold';
  src: url('../../public/fonts/Inter/Inter-Bold.ttf'),
    url('../../public/fonts/Inter/Inter-Bold.ttf');
}

.inter-regular {
  font-family: 'InterRegular' !important;
}


.archivo-black {
  font-family: 'ArchivoBlack' !important;
}


.inter-bold {
  font-family: 'InterBold' !important;
}

.gradient-text {
  background: rgb(0, 255, 163);
  background: linear-gradient(222deg, rgba(0, 255, 163, 1) 0%, rgba(220, 31, 255, 1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  /* Makes the foreground color transparent so the background gradient shows */
}

.gradient-text-alt {
  background: rgb(0, 255, 163);
  background: linear-gradient(222deg, #00d5ff 0%, #dc1fff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  /* Makes the foreground color transparent so the background gradient shows */
}

.pointer {
  cursor: pointer;
}

.chakra-form-control:has(div.dropdownfocused) {
  z-index: 9999 !important;
}

.text-left {
  text-align: left !important;
}

.searchable-select {
  input {
    outline: none !important;
    box-shadow: none !important;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  // target only -webkit browsers

  select,
  option {
    background-image: none;
    /* remove the value that chrome dose not use */
    background-color: #333;
    /* set the value it does */
    border-radius: 4px;
    /* make it look kinda like the background image */
    border: 1px solid #888;
  }
}

// Variables
$chat-width: 380px;
$purple: #4c31e3;
$pink: #fb4bdb;
$box-bg: #052032;
$neo-gradiant: linear-gradient(45deg, $purple, $pink);
$neo-gradiant-hover: linear-gradient(45deg, lighten($purple, 7), lighten($pink, 7));
$box-radius: 7px;

.bodywithadmin {
  padding-top: 0px;
}

.aitradeslider {
  li {
    &.slick-active {
      > div {
        @apply bg-gradient-to-bl from-teal-400 to-fuchsia-500 rounded-full opacity-100;
      }
    }
  }
}

// Layout Styles
@import './layout/index';

// Mixins
@import './mixins/index';

// Components
@import './components/index';

// Mobile Responsive Styles (Should always be last in this file)
@import './mobile/index';

// custom radio
.radio-custom[data-checked] {
  background-color: #ffbe4d !important;
  border-color: #ffbe4d !important;
}

.hover\:opacity-80:hover {
  opacity: 0.8;
}

.wallet-adapter-modal {
  z-index: 99999999 !important;
}




.tn-atom__sbs-anim-wrapper {
  position: fixed;
  top: 0;
   height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  transition: opacity 0.5s ease-out;
}

.tn-atom__img {
  opacity: 0.6;
  max-width: 100%;
  max-width: 600px;
  object-fit: contain;
  transition: transform 0.5s ease-out;
}


@media (max-width: 768px) {
 .tn-atom__img {
    visibility: hidden;
    transform: scale(1.5);
    /* Smaller scale for mobile devices */
  }
}
.background-white{
  background-color: #fff;
}

.potato-radius{
  border-radius: 30px !important;
}

.potato-radius button {
  border-radius: 30px !important; /* example */
}
.potato-radius-sm{
  border-radius: 10px !important;
}
.text-dark-potato{
  color: #444444;
}

.border-dark-potato{
  border:2px solid #CCCCCC;
}

.border-dark-potato-dashed{
  border:2px dashed #CCCCCC;
}


.potato-btn-radius{
  border-radius: 35px;
}

.border-primary-potato{
  border-color:   #FC822F;
}

.border-primary-potato-dashed{
  border:2px dashed #FC822F;
}

.text-primary-potato{
  color: #FC822F;
}
.text-secondary-potato{
  color: #FF4ADA;
}

.primary-potato-btn{
  background-color:#FC822F !important ;
  box-shadow: 0 4px 13px 0px rgba(252, 130, 47, 0.73);
  &:hover{
    background-color: #eb792b;
    box-shadow: 0 5px 15px #fc812f5d;
  }
}

.primary-potato-btn-outline{
  color:#FC822F !important;
  background-color: #FFF;
  border:2px solid #FC822F !important ;
  box-shadow: 0 4px 13px 0px rgba(252, 130, 47, 0.73);
  &:hover{
     box-shadow: 0 5px 15px #fc812f5d;
  }
}

.potato-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;

    &:hover {
      background: #555;
    }
  }

  -webkit-overflow-scrolling: touch;
}



.active{
  background-color:#FC822F !important ;
}

.secondary-potato-btn{
  max-width: 150px;
  color: #FF4ADA!important;
  background-color: #FFE0F2 !important;
  box-shadow: 0 4px 13px 0px rgba(255 74 218 / 73%);
  &:hover{
    background-color: #d7b0c7!important;
    box-shadow: 0 5px 15px rgba(255 74 218 / 20%);
  }
}

.default-potato-btn{
  max-width: 150px;

  color: #444444;
  background-color: #EBEDEF;
  box-shadow: 0 4px 13px 0px rgba(175 175 175 / 73%);
  &:hover{
    background-color: #d0d1d2;
    box-shadow: 0 5px 15px rgba(175 175 175 / 50%);
  }
}

.potato-primary-color{
  color:#FC822F !important;
 }

.potato-primary-color-shadow{
  color:#FC822F ;
  box-shadow: 0 4px 13px 0px rgba(252, 130, 47, 0.73);
 }

.main-menu-dropdown:hover{
  color: #FC822F;
 }
.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  text-transform: uppercase;
  position: relative;
  width: 100%;
  text-align: center;
}
@media (max-width: 768px) {
  .section-title h2 {
    font-size: 22px;
     
  }
 }
 

.section-title h2:before {
  content: "";
  position: absolute;
  display: block;
  width: 160px;
  height: 1px;
  background: color-mix(in srgb, #fff, transparent 60%);
  left: 0;
  right: 0;
  bottom: 1px;
  margin: auto;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 3px;
  background: #FC822F;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}


/* FAQ Section Enhancements */
.faq .section-title {
  margin-bottom: 40px;
}

.faq .faq-list ul {
  padding: 0;
  list-style: none;
}

.faq .faq-list ul li {
  margin-bottom: 20px;
  border: 1px solid rgba(102, 100, 100, 0.13);
  border-radius: 31px;
  overflow: hidden;
}

.faq .faq-list ul li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 15px 20px;
  background: #fff;
  color: #444444;
  font-size: 20px;
  /* Increased question font size */
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.faq .faq-list ul li a i {
  margin-right: 10px;
  font-size: 20px;
  display: none;
}

.faq .faq-list ul li a .icon-show,
.faq .faq-list ul li a .icon-close {
  font-size: 24px;
  transition: all 0.3s ease-in-out;
}

.faq .faq-list ul li a.collapsed .icon-show {
  display: inline-block;
}

.faq .faq-list ul li a.collapsed .icon-close {
  display: none;
}

.faq .faq-list ul li a:not(.collapsed) .icon-show {
  display: none;
}

.faq .faq-list ul li a:not(.collapsed) .icon-close {
  display: inline-block;
}

.faq .faq-list ul li p {
  padding: 15px 20px;
  margin: 0;
  background: RGB(101 101 101 / 9%);
  font-size: 18px;
  /* Increased answer font size */
  border-top: 1px solid rgba(102, 100, 100, 0.13);
}

/* Remove bullets */
.faq .faq-list ul {
  list-style: none;
  padding-left: 0;
}

.faq .faq-list ul li a:hover {
  background: #fff;
  color: #FC822F;
}

.faq .faq-list ul li a:hover .icon-show,
.faq .faq-list ul li a:hover .icon-close {
   color: #FC822F;
}
.collapsefq:not(.show) {
  display: none;
}


.swap-ui {
  background: #ffffff;
  border: 1px solid #FC822F;
  color: #444444;

}

.swap-ui-placeholder{
  background-color: #F1F1F1;
  position: relative;
}

.swap-btn {
  position: absolute;
  right: 10px;
  top: 10px;

}
.no-hover{
  pointer-events: none;
}
.no-shadow{
  text-shadow: none !important;
}

.potato-modal-bg{
  background-color: #F9FAFB !important;
}

.collections-box{
  background-color: #fff !important;
  border: 1px solid #FC822F;

}

.collection-card{
  background-color: #E9EBEE;

  &:hover{
    background-color: #FC822F;

  }
}

.mobile-swap-bg{
  background-color: #F1F1F1 !important;
}

.table-bg{
  background-color: #F1F1F1;
}

button:focus, input:focus{outline:0;}
.react-select__control {
  border: none !important;
}

.react-select__control{
  &:hover, &::after, &:focus {
    border: none !important;
    outline: none !important;
  }
}


.secondary-potato-btn:disabled, .disabled {
  opacity: 0.6;
  cursor: not-allowed;
  box-shadow: none;
  background-color: #cccccc;

}

.footer-tulle-logo {
  text-align: center;
  margin-top: 10px;
}

.footer-tulle-logo img {
  max-width: 150px;
  /* Adjust this value to make the logo small */
  height: auto;
  display: block;
  margin: 0 auto;
}

.copyright a:hover{
  color:#FC822F !important;

}

.booster{
  background-color: #008E20;
}

@media (max-width: 768px) {
  .value-prop {
     font-size: 30px !important;
   }
   .value-prop-sub{
    font-size: 15px !important;

   }

   .chakra-avatar {
    max-width: 60px !important;
    max-height: 60px !important;
   }

   .avatar-name{
    font-size: 15px !important;
   }
 }

.chakra-modal__close-btn {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  z-index: 10 !important;
  color: #1b04046d !important;
  width: var(--chakra-sizes-8) !important;
  height: var(--chakra-sizes-8) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.floating-potato {
  width: 100px;
   cursor: pointer;
  transition: transform 0.2s;
}

.floating-potato:hover {
  transform: scale(1.1);
}
@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
}

.message-animation {
  animation: fadeInOut 1s ease-in-out;
}

.floating-potato {
  transition: top 0.1s linear;
}

.announcement{
  background: linear-gradient(222deg, #d9b892 0%,  #FC822F 100%);
}