.grouped-item {
  background: #062c46;
  border-radius: $box-radius;
  padding: 20px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: #164c77;
  }

  h3 {
    font-size: 16px;
    font-weight: 700;
    margin-top: 35px;
  }

  h5 {
    margin-top: 20px;
  }

  .grouped-image {
    position: relative;
    transform: rotate(-2deg);

    .chakra-aspect-ratio {
      &::before {
        padding-bottom: 0px;
      }
    }

    .image-wrapper {
      position: relative;
      z-index: 5;
      image-rendering: pixelated;
      image-rendering: -moz-crisp-edges;
      image-rendering: crisp-edges;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #5f5f5f;
      transform: rotate(-2deg);
    }

    &:before {
      z-index: 1;
      background: #21465e;
      transform: rotate(-6deg);
    }
  }

  &:nth-child(odd) {
    .grouped-image {
      transform: rotate(3deg);
    }
  }
}

.bid-placed {
  svg {
    polyline {
      stroke: #fff;
    }
  }
}

#input-color {
  border-color: #fc822f;
}

#input-color {
  border-color: #fc822f;
}

.grid-view {
  .box {
    background: transparent;
    padding: 0px;
  }

  .bidding-grid-item {
    .list-controls {
      width: 100%;

      .icon-button {
        width: 50%;
        height: 30px;
      }
    }
  }

  .grid-view-header {
    h2 {
      border-bottom: 1px solid #0e2b3f;
      padding-bottom: 15px;
      margin-bottom: 29px;
    }

    .view-toggle-btn {
      width: 30px;
      height: 30px;
      margin-left: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #2b4452;
      cursor: pointer;

      svg {
        width: 21px;
        height: 21px;
      }

      &.rows {
        svg {
          width: 27px;
          height: 27px;
        }
      }

      &.active {
        color: #407490;
      }
    }
  }

  .checkmark-div {
    height: 20px;
    width: 20px;
    background-color: #fc822f;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 45%;
    right: 7%;

    .bid-checkmark {
      color: white;
    }
  }

  .grid-view-wrapper {
    /* display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 23px;*/
    font-family: "InterRegular";

    .grid-item {
      &.selected {
        .contents {
          border-color: #fc822f;
        }
      }
    }

    .grid-item {
      border-radius: $box-radius;
      min-width: 0;

      .image-wrap {
        position: relative;

        .locked-overlay {
          position: absolute;
          background-color: rgba(0, 0, 0, 0.72);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;

          svg {
            width: 50px;
            height: 50px;
          }
        }
      }

      .place-bid-btn {
        display: flex;
        flex-direction: row;
        text-transform: uppercase;
      }

      .contents {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        min-width: none;
        position: relative;
        padding: 10px;

        .media-holder {
          width: 100%;
          position: relative;

          .is-bid-check {
            position: absolute;
            right: 15px;
            bottom: 15px;

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }

        .details {
          width: 100%;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
        }

        .image-wrapper {
          width: 100%;
          background: #051c2d;
          //border-radius: 6px;
          max-height: 228px;
          image-rendering: pixelated;
          image-rendering: -moz-crisp-edges;
          image-rendering: crisp-edges;
        }

        .price-info {
          display: flex;
          flex-direction: column;
          background: rgb(102 100 100 / 13%);
          padding: 8px;
          margin-top: 1rem;
          width: 95%;
          margin-right: auto;
          margin-left: auto;
          justify-content: space-evenly;
          flex-wrap: wrap;

          .price {
            //width: 50%;
            margin-top: 5px;
            display: flex;
            flex-direction: row;
            text-align: center;
            border-radius: 5px;
            justify-content: space-between;
            font-size: 11px;

            &:first-child {
              margin-top: 0px;
            }

            .bold {
              font-family: "InterBold";
            }

            p {
              font-size: 12px;
            }

            &.your-bid {
              background: #0c416a;
              // min-width: 79px;
              // margin-left: 10px;
            }

            .label {
              display: flex;
              flex-direction: row;

              .p {
                font-size: 0.65rem;
              }
            }
          }
        }

        .nft-details {
          width: 95%;
          margin: 0px auto;

          //  padding: 15px 10px 8px;
          text-align: start;

          p {
            font-size: 0.8rem;
          }

          h3 {
            font-size: 1rem;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            color: #fc822f;
            margin-top: 15px;
          }
        }

        .grid-item-footer {
          font-size: 14px;
          width: 100%;
          display: flex;
          padding: 5px;
          font-weight: 700;
          min-height: 60px;
          align-items: center;
          border-bottom-right-radius: $box-radius;
          border-bottom-left-radius: $box-radius;
          margin-top: auto;

          .remove-icon {
            margin-left: 8px;
            background: #dc3545;

            &:hover {
              background: lighten(#dc3545, 10);
            }
          }

          .reserve-label {
            font-size: 15px;
            margin: 0 10px 0 auto;
            width: 100%;
            text-align: right;
          }
        }
      }
    }
  }
}

.place-bid {
  display: flex;
  gap: 7px;
  font-size: 12px;
  align-items: center;
  text-transform: uppercase;
}

.image-wrapper {
  width: 100%;
}

/*@media only screen and (max-width: 1726px) {
    .grid-view {
        .grid-view-wrapper {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
}

@media only screen and (max-width: 1406px) {
    .grid-view {
        .grid-view-wrapper {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

@media only screen and (max-width: 1248px) {
    .grid-view {
        .grid-view-wrapper {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .grid-view {
        .grid-view-wrapper {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}*/

.search-div {
  input {
    background: radial-gradient(52.93% 546.03% at 51.41% 77.05%,
        rgb(102 100 100 / 13%) 0%,
        rgba(255, 255, 255, 0.0605811) 75.16%,
        rgba(255, 255, 255, 0.024) 100%);
  }
}

.filters {
  .chakra-switch {
    flex-direction: row-reverse;
    gap: 10px;
  }
}

@media only screen and (max-width: 941px) {
  .search-div {
    margin-top: 30px;
  }

  .filters {
    flex-direction: column;

    .group-switch {
      margin: 15px 0 25px !important;
    }

    .layout-toggle {
      margin-top: 23px !important;
    }

    .filter-input {
      max-width: unset !important;

      &.collection {
        max-width: unset !important;
      }

      &.sort {
        max-width: 100% !important;
        margin: 15px 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 803px) {
  .grid-view {
    .grid-view-wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media only screen and (max-width: 443px) {
  .grid-view {
    .grid-view-wrapper {
      grid-gap: 10px;
      grid-template-columns: 1fr;
    }
  }
}