.revenue-share-message {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: flex-start;
    padding: 25px;
    margin-bottom: 60px;

    &:after {
        content: "";
        bottom: -30px;
        left: 0;
        display: block;
        background: rgba(255, 255, 255, 0.2);
        width: 100%;
        height: 1px;
        position: absolute;
    }

    .close-icon {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 12px;
            height: 12px;
        }
    }

    h2 {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 5px;

        span {
            color: #ed6cd8;
        }
    }

    img {
        width: 50px;
        height: auto;
        margin: 0 20px 0 5px;
    }
}

@media only screen and (max-width: 700px) {
    .revenue-share-message {
        padding: 20px;

        h2 {
            font-size: 18px;
            margin-bottom: 20px;
        }
    }
}