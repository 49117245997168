.wait-list {
    .or-label {
        font-weight: 700;
    }
}

#aihero {
    width: 4.5rem;
    margin-left: auto;
    margin-right: auto;
}

.icondanger {
    color: #FC822F;
    margin-top: -0.85rem;
}

.aicheck-allowlist {
    svg {
        font-size: 30px;
        margin-right: 5px;
    }

    background: linear-gradient(90deg, rgb(102 100 100 / 13%) -1.52%, rgba(255, 255, 255, 0.024) 104.35%);
    //padding: 9px 9px 7px;

    padding-top: 1.3rem;
    padding-bottom: 1.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.8rem;
    border-radius: 4px;
    color: #fff;
    //margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    .content-p {
        margin-left: 0.3rem;
        text-align: left;
    }

}

@media only screen and (max-width: 993px) {
    .wait-list {
        .form-label {
            width: 100%;
            margin-bottom: 10px;
        }

        .form-input {
            margin: 15px 0 0 0;
            width: 100%;
        }

        .form-row {
            padding: 20px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .chakra-stack {
            flex-grow: 1;

            .chakra-text {
                font-size: 16px;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .wait-list {
        .wallet-row {
            .form-input {
                display: flex;
                flex-direction: column;

                .chakra-form-control {
                    margin: 15px 0 0 0;
                }

                .chakra-button {
                    width: 100%;
                    margin: 0 0 15px 0;
                }

                .or-label {
                    display: block;
                    padding: 0 15px;
                    font-size: 17px;
                    margin: 0;
                }
            }
        }
    }

    .aicheck-allowlist {
        flex-direction: column;
        justify-content: center;
    }
}

.grad-btn {
    background: linear-gradient(45deg, #4c31e3, #fb4bdb) !important;
    padding: 20px 15px;
}

.wait-title {
    margin-bottom: 10px !important;
    margin-top: 10%;
}

.sub-waitlist {
    margin-bottom: 15px;
    text-align: center;
}