@media only screen and (max-width: 590px) {
    .room-title {
        .chakra-stack:first-of-type {
            button {
                font-size: 18px;
            }
        }

        .chakra-button {
            padding: 10px 13px;
            height: auto;
            font-size: 16px;
        }
    }
}

@media only screen and (max-width: 420px) {
    .room-title {
        @include box();

        flex-direction: column !important;

        .chakra-stack:first-of-type {
            width: 100%;
            margin-bottom: 25px;

            button {
                width: 100%;
                padding: 5px 10px;
                border-radius: 5px;
                border: 1px solid rgba(255,255,255, 20%);

                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }
    }
}