.party-header {
  width: 100%;
  display: flex;
  flex-direction: column;

  .breadcrumb {
    width: 100%;

    ol {
      display: flex;
      width: 100%;
    }

    .breadcrumb-current {
      flex-grow: 1;
      overflow: hidden;

      .breadcrumb-text {
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 20px;
      }
    }
  }

  .party-title {
    text-transform: capitalize;
    margin: 20px 0;

    h2 {
      font-size: 30px;
      font-weight: 700;
      margin-right: 40px;
    }

    button {
      min-width: 130px;
    }
  }

  .party-stats {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    margin-top: 8px;
    padding: 10px;

    .chakra-stat {
      padding: 10px;
      border-radius: 8px;
    }
  }
}

.room-title {
  .chakra-menu__menu-button {
    background: none !important;
  }
}

.room-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 13px;

  .room-title {
    margin-bottom: 0;
  }
}

.trade-info {
  display: flex;
  margin-top: 35px;

  .trade-gif {
    max-width: 30%;
    margin: 0;
    background: none;
  }

  .trade-details {
    background: #062438;
    flex-grow: 1;
    margin-left: 20px;
    padding: 30px 50px;
  }
}

.chakra-modal__overlay {
  z-index: 99999 !important;
}

.chakra-modal__content-container {
  z-index: 999991  !important;
}

.chat-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 15px;
  min-height: 68px;
  background: #062a41;

  .chakra-spinner {
    margin-left: 10px;
  }

  .avatar {
    margin-right: 10px;
  }

  .chakra-button {
    margin-left: auto;

    svg {
      width: 22px;
      height: 22px;
    }
  }
}

.party-section-top {
  .details-body {
    font-size: 30px;

    @media screen and (max-width: 720px) {
      text-align: left;
    }
  }

  .top-info {
    display: flex;
  }

  .top-box {
    //background: #0b1e2c;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    border-radius: 6px;
    width: fit-content;

    svg {
      width: 28px;
      height: 28px;
      opacity: 0.2;
      margin-right: 20px;
    }

    .box-content {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .box-label {
      font-size: 14px;
      opacity: 0.5;
      margin-bottom: 5px;
      display: block;
    }

    &.users {
      margin-left: 20px;

      &:hover {
        background: #112b41;
        cursor: pointer;
      }
    }

    &.info {
      margin-left: 20px;
    }
  }
}

.user-properties {}

.user-name {
  font-size: 14px;
  font-weight: 700;
  color: #fc822f;
}

.room-name {
  font-size: 30px;

  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
}

.new-bg-transparent {
  background: rgb(60 60 60 / 17%)  !important;
  border: 1px solid rgb(102 100 100 / 13%);

  &.darker {
    background: rgba(0, 0, 0, 0.85) !important;
  }
}

.trait-bg-transparent {
  background: rgb(60 60 60 / 17%)  !important;
  border: 1px solid rgb(102 100 100 / 13%);
  transition: background 0.1s ease;

  &:hover {
    background: rgb(40 40 40 / 30%) !important;
  }

  &.darker {
    background: rgba(0, 0, 0, 0.85) !important;

    &:hover {
      background: rgba(0, 0, 0, 0.95) !important;
    }
  }
}

.trait-bg-transparent-selected {
  background: #FFF0F8 !important;
  border: 1px solid rgba(255, 240, 248, 0.5);
  transition: background 0.1s ease;
  color: #800080;

  &:hover {
    background: darken(#FFF0F8, 5%) !important;
  }

  &.darker {
    background: darken(#FFF0F8, 10%) !important;

    &:hover {
      background: darken(#FFF0F8, 15%) !important;
    }
  }
}

.title-div {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.properties-div {
  width: 60%;

  display: flex;
  flex-direction: row;
  //margin-top: -1rem;
  justify-content: space-between;

  @media screen and (max-width: 1440px) {
    width: 90%;
  }

  @media screen and (max-width: 440px) {
    // flex-direction: column;
    // align-items: center;
  }
}

.room-type {
  font-size: 14px;
  font-style: italic;
  color: #FF4ADA;
}

.chakra-avatar__excess {
  background-color: #fff !important;
  color: #FF4ADA;
}

.room-settings-text {
  font-size: 11px;
  font-style: italic;
  color: #FF4ADA;
}

.party-page {
  .party-section-top {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    background-color: #052032;
    padding: 17px;
    border-radius: 5px;
    gap: 20px;

    .party-details {
      flex-grow: 1;
      border-top-left-radius: 0;
      margin-bottom: 40px;
      border-top-right-radius: 0;

      &:empty {
        display: none;
      }
    }

    .party-title {
      font-size: 30px;
      flex-grow: 1;
      overflow: hidden;

      .room-type {
        font-size: 14px;
        opacity: 0.5;
        //margin-bottom: 5px;
        display: block;
      }

      .title-content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        text-transform: capitalize;
        font-weight: bold;
        font-weight: bold;
      }
    }

    .party-progress {
      display: flex;
      font-weight: 700;
      margin-left: auto;
      align-items: center;
      width: 100%;

      .party-progress-step {
        background: $box-bg;
        padding: 15px 30px 15px 20px;
        display: flex;
        align-items: center;
        color: #0e3955;
        position: relative;

        .arrow {
          position: absolute;
          width: 16px;
          height: 16px;
          top: calc(50% - 8px);
          right: -3px;
          color: #0f3955;
        }

        .icon {
          min-width: 20px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: #0f3955;
          margin-right: 10px;
          display: flex;
          position: relative;
          top: -2px;
          align-items: center;
          justify-content: center;

          svg {
            width: 12px;
            height: 12px;
            display: none;
          }
        }

        p {
          margin: 0;
        }

        &:nth-child(2) {
          border-top-left-radius: 8px;
        }

        &:last-child {
          border-top-right-radius: 8px;
        }

        &.canceled {
          color: #de4a4a;

          .icon {
            background: #de4a4a;
            color: #fff;

            svg {
              display: block;
            }
          }
        }

        &.in-progress {
          color: #8d8d8d;

          .icon {
            background: #8d8d8d;
            color: #454545;

            svg {
              display: block;
            }
          }
        }

        &.done {
          color: #fff;

          .icon {
            background: #389619;

            svg {
              display: block;
            }
          }
        }
      }
    }
  }

  .party-section {
    margin-bottom: 40px;
  }
}

.details-body {
  font-size: 1.4rem;
  margin-top: 5px;
  text-align: center;
}

.room-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  min-height: 400px;
  border-top-right-radius: 0;

  h4 {
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 10px;
  }

  .icon {
    width: 70px;
    height: 70px;
    margin-bottom: 40px;
    color: #fff;
  }
}

.listings-table-wrap {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
}

.section-title {
  width: 100%;
  margin-bottom: 30px;
}

.table-pagination {
  margin-top: 15px;
}

.party-preview {
  margin-top: 40px !important;

  .listing-table {
    min-width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;

    .empty-listings {
      padding: 20px;
      font-weight: 700;
    }
  }

  .participant-list {
    flex-grow: 1;
    margin-left: 40px;
    max-height: 500px;
    overflow-y: auto;

    .empty-list {
      text-align: center;
      padding: 20px;
      font-weight: 700;
    }

    .participant-list-item {
      background: #062438;
      margin: 10px 0;
      padding: 10px;
      border-radius: 7px;
      font-weight: 700;

      .chakra-avatar {
        margin: 0 4px;
      }
    }
  }
}

@media only screen and (max-width: 724px) {
  body.chat-active {
    .page-content {
      position: relative;
    }

    .bottom-fixed-bar {
      min-height: 110px !important;

      .bottom-bar-content {
        flex-direction: column;
        align-items: flex-start;

        .bottom-bar-controls {
          margin-left: 0;
          margin-top: 1rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .party-header {
    .party-title {
      h2 {
        font-size: 25px;
      }
    }
  }

  .party-preview {
    flex-direction: column !important;

    .participant-list {
      margin-left: 0;
      width: 100%;
    }

    .listing-table {
      width: 100%;
      margin-bottom: 40px;
    }
  }
}

body.chat-active {
 
  #container {
    display: flex;
    height: 100%;
  }

  #side-panel {
    width: 100%;
    min-width: $chat-width;
    max-width: $chat-width;
  }

  #side-panel:empty {
    display: none;
  }

  #nav-bar {
    .nav-content {
      max-width: calc(1300px + 80px);
    }
  }

  #page {
    flex-grow: 1;
    width: calc(100% - #{$chat-width});
    padding-top: 0;
    display: flex;

    .page-content {
      max-width: unset;
      height: 120px;
      flex-grow: 1;
      overflow: hidden;
      overflow-y: auto;
      padding-top: 50px;

      .party-page {
        margin: 0 auto;
        max-width: 1300px;
      }
    }
  }

  .bottom-fixed-bar {
    background: #021520 !important;
    width: calc(100% - #{$chat-width});
    min-height: 95px;
  }

  .bottom-bar-content {
    display: flex;
    align-items: center;
    width: 100%;

    .issue-button {
      margin: 0 20px 0 0;
    }

    .bottom-bar-controls {
      margin-left: auto;
    }
  }

  .bottom-bar-title {
    font-size: 24px;
    font-weight: 700;
  }

  .swap-info-wrapper {
    display: flex;
    align-items: center;

    .swap-info {
      display: flex;
      align-items: center;
      font-size: 21px;
      margin-left: 10px;

      svg {
        position: relative;
        top: -1px;
        width: 22px;
        height: 22px;
        margin-right: 10px;
        color: #c95353;
      }

      &.done {
        svg {
          color: #5cb85c;
        }
      }
    }
  }

  #footer {
    display: none;
  }

  #nav-bar {
    position: relative;
    margin-bottom: 0;
  }

  // .decline-input{
  //     min-height: 10rem;
  //     width: 100%;
  // }

  .declined-div {
    text-align: center;

    h1 {
      font-size: 1rem;
    }

    p {
      font-size: 0.75rem;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }

  .decline-text {
    font-size: 0.7rem;
  }

  .bottom-fixed-bar {
    padding: 5px 30px;
    box-shadow: none;
    border: none;

    @media screen and (max-width: 450px) {
      padding: 15px 30px;
    }

    .bar-button {
      padding: 24px;
      position: relative;
      top: -1px;

      @media screen and (max-width: 450px) {
        padding: 10px;
      }
    }
  }

  .chat-side-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-left: 1px solid #012132;

    .chat-header {
      background: #02121c;
      padding: 26px;
      border-bottom: 1px solid #031e30;
      height: 92px;
      display: flex;
      align-items: center;

      .voice-connected {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 0;
        width: 100%;

        .controls {
          display: flex;
          align-items: center;
          margin-left: auto;

          .voice-chat-button {
            margin-left: 24px;
            padding: 5px;

            &:hover {
              opacity: 0.8;
            }

            &.users-button {
              display: flex;
              align-items: center;
              font-weight: 700;

              .users-count {
                position: relative;
                top: 2px;
                margin-left: 5px;
              }
            }

            svg {
              width: 23px;
              height: 23px;
            }
          }
        }

        .label {
          position: relative;
          padding-left: 20px;

          &:before {
            content: "";
            background: #31b921;
            width: 10px;
            height: 10px;
            top: 7px;
            left: 2px;
            border-radius: 50%;
            position: absolute;
            animation: blink 2s infinite;
          }
        }
      }
    }

    .chat-panel {
      flex-grow: 1;
    }
  }
}

#chat-toggle {
  display: none;
}

@media only screen and (max-width: 1248px) {
  body.chat-active {
    overflow: auto;

    .title-div {
      width: 100%;
      display: flex;

      .room-des {
        text-align: start;
      }
    }

    .properties-div {
      width: 100%;
      display: flex;
    }

    .avatar-group-wrapper {
      display: flex;
      justify-content: center;

      @media screen and (max-width: 720px) {
        justify-content: flex-start;
      }
    }

    #page {
      width: 100%;

      .page-content {
        overflow: visible;
      }
    }

    #container {
      display: block;
    }

    #chat-toggle {
      position: fixed;
      background: $neo-gradiant;
      bottom: 14px;
      border-radius: 50%;
      right: 16px;
      z-index: 99999;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 65px;
      height: 65px;

      svg {
        width: 30px;
        height: 30px;
        position: relative;
        top: 1px;
      }

      &:hover {
        background: $neo-gradiant-hover;
      }

      .close {
        display: none;
      }

      .bubble {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .counter {
          position: absolute;
          top: -5px;
          left: -5px;
          background: #f12929;
          border-radius: 50%;
          font-size: 14px;
          font-weight: 800;
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          outline: 3px solid #051a28;
          z-index: 99;
          transform: scale(0.9);
        }
      }
    }

    #side-panel {
      position: fixed;
      top: 0;
      right: 100%;
      max-width: unset;
      z-index: 9999;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);

      .chat-side-panel {
        width: 50%;
        margin-left: auto;
        max-width: unset;

        .chat-header {
          .controls {
            margin-right: 60px;
          }
        }
      }
    }

    .bottom-fixed-bar {
      width: 100%;

      .bar-button {
        margin-right: 70px;
      }
    }

    &.chat-open {
      #side-panel {
        right: 0;
      }

      #chat-toggle {
        top: 0;
        bottom: auto;
        background: none;

        .close {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            svg {
              opacity: 0.8;
            }
          }

          svg {
            width: 23px;
            height: 23px;
          }
        }

        .bubble {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 1086px) {
  .trade-info {
    .trade-details {
      padding: 10px 20px;
    }
  }
}

@media only screen and (max-width: 1130px) {
  .page-content {
    .party-page {
      .party-section-top {
        flex-direction: column;

        .top-info {
          margin-top: 20px;

          .top-box {
            min-width: unset;
            width: 50%;
          }
        }

        .party-details {
          border-top-left-radius: 0;
          margin-bottom: 10px;
        }

        .party-progress {
          flex-wrap: wrap;
          width: 100%;
          justify-content: space-between;

          .party-progress-step {
            background: #062438;
            width: 33.3%;
            justify-content: center;

            .arrow {
              display: none;
            }
          }

          .party-title {
            top: -20px;
            margin-bottom: 20px;
            min-width: 100%;
            text-align: center;
            overflow: visible;

            .title-content {
              text-overflow: unset;
              overflow: visible;
              white-space: normal;
            }
          }
        }
      }
    }
  }

  .party-header {
    .party-stats {
      margin-top: 25px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;

      .chakra-stat {
        background: #062438;
      }
    }
  }
}

@media only screen and (max-width: 731px) {
  body.chat-active.chat-open #chat-toggle {
    top: unset;
  }

  body.chat-active {
    #side-panel {
      .chat-side-panel {
        width: 80%;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  body.chat-active {
    #side-panel {
      .chat-side-panel {
        width: 100%;
        border-left: none;
      }
    }

    &.chat-open {
      #userback_button_container .userback-button-sw {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 620px) {
  .trade-info {
    flex-direction: column;

    .trade-gif {
      max-width: 300px;
      margin: 0 auto;
    }
  }

  .party-header {
    .breadcrumb {
      ol {
        justify-content: center;
      }

      .breadcrumb-text:last-of-type {
        margin-right: 0;
      }

      .breadcrumb-current {
        flex-grow: 0;
      }
    }

    .party-title {
      flex-direction: column;
      text-align: center;

      h2 {
        margin: 10px 0 40px 0;
      }
    }
  }

  .party-page {
    .party-section-top {
      .party-steps {
        display: flex;
      }

      .party-details {
        border-top-right-radius: 0;
      }
    }

    .party-section {
      margin-top: 40px !important;
    }
  }
}

@media only screen and (max-width: 540px) {
  .party-preview {
    margin-top: 20px !important;

    .listing-table {
      margin-bottom: 20px;
    }
  }

  body.chat-active {
    &.chat-open {
      .voice-connected {
        .label {
          margin-right: 0;

          &:before {
            top: -5px !important;
            left: -5px !important;
          }

          .text {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 447px) {
  .page-content {
    .party-page {
      .party-section-top {
        .top-info {
          flex-direction: column;

          .top-box {
            width: 100%;
            margin-left: 0;

            &:last-child {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }

  .party-header {
    .party-stats {
      text-align: center;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;

      .chakra-stat__number {
        font-size: 18px;
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .party-page {
    .party-section-top {
      .party-progress {
        .party-progress-step {
          font-size: 12px;

          .icon {
            min-width: 15px;
            width: 15px;
            height: 15px;

            svg {
              width: 10px;
              height: 10px;
            }
          }
        }
      }

      .party-details {
        padding-bottom: 10px;

        .user-info {
          margin-top: 15px;
          padding: 5px;
          font-size: 14px;

          .chakra-text {
            margin-left: 0;
          }
        }

        .chakra-stack {
          width: 100%;

          h2 {
            width: 100%;
            text-align: center;
            font-size: 16px;
          }
        }
      }
    }
  }
}

@keyframes blink {
  0% {
    background: #31b921;
  }

  50% {
    background: #1d7313;
  }

  100% {
    background: #31b921;
  }
}

.time-wrapper-room {
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5px;

  @media screen and (max-width: 720px) {
    align-items: flex-start;
  }

  p {
    color: white;
    margin-bottom: 3px;
    opacity: 1;
  }

  .timer {
    display: inline-flex;
    color: #fff;
    align-items: center;
    font-size: 13px;

    .timer-item {
      .val {
        color: white;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 4px;
        font-size: 30px;
        font-weight: 700;

        @media screen and (max-width: 500px) {
          font-size: 15px;
        }
      }
    }
  }
}

.listed-nfts-info-holder {
  @media screen and (max-width: 500px) {
    display: flex;
    align-items: center;
    gap: 10px;

    h4,
    .details-body {
      margin-top: 0px;
    }
  }
}