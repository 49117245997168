body.sales-report-active:not(.is-room) {
  #nav-bar {
    top: 70px;
  }

  #page {
    padding-top: calc(196px);
  }
}

body.is-room {
  #sales-report-message {
    display: none;
  }
}

#sales-report-message {
  position: fixed;
  width: 100%;
  height: 70px;
  z-index: 99;
  background: linear-gradient(90deg, #8b66b6, #363d86);
  padding: 20px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 12px;
    width: 20px;
    height: 20px;
    position: relative;
    top: -1px;
  }

  .chakra-button {
    margin-left: auto;
    padding: 24px 24px 21px;
  }
}

@media only screen and (max-width: 1035px) {
  #sales-report-message {
    position: relative;
    height: auto;
  }

  body.sales-report-active:not(.is-room) {
    #page {
      padding-top: 0;
    }

    #nav-bar {
      top: 0;
    }
  }
}
