.terms {
    h3 {
        font-size: 20px;
        font-weight: 700;
        margin-top: 35px;
    }

    p {
        margin: 20px 0;
    }

    ul, ol {
        padding-left: 30px;
        line-height: 1.6em;
    }
}