body.feedback-modal {
    #userback_button_container {
        background: #f0f0f0 !important;

        .userback-button-w,
        .userback-controls-close {
            display: none !important;
        }

        .userback-controls-logo {
            max-height: 110px;
        }

        .userback-controls {
            box-shadow: none !important;
            width: 100%;
            height: 80%;
            max-width: 1000px;
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .userback-controls-step {
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
        }

        .userback-controls input,
        .userback-controls textarea {
            border-color: #909090;
        }
    }
}

@media only screen and (max-width: 1010px) {
    body.feedback-modal {
        #userback_button_container {
            background: #fff !important;
        }
    }
}

@media only screen and (max-width: 800px) {
    body.feedback-modal {
        #userback_button_container {
            .userback-controls {
                top: 0 !important;
                height: 100%;
            }
        }
    }
}