.chakra-button.button,
.button {
  background: $neo-gradiant;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 13px 8px 11px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background: $neo-gradiant-hover;
  }

  &.small {
    padding: 8px 15px 5px !important;
    font-size: 13px;
  }

  &.border {
    background: none;
    border: 1px solid #205172;
    text-decoration: none !important;

    &:hover {
      background: rgba(255, 255, 255, 0.09);
    }
  }

  &.action {
    background: #fc822f;

    &:hover {
      background: #6c63da;
    }
  }
}
