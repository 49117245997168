.nft-loader{
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10%;
    padding-bottom: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    

    
}

.loadingtext {
    padding-top: 1.2rem;
    font-size: 0.8rem;
}