.updates {
    padding: 0 40px;

    .page-header {
        display: flex;
        align-items: center;
        justify-content: center;
        outline: 1px solid red;
    }

    .update-item {
        text-align: left;
        padding: 25px;
        border-radius: 7px;
        margin-bottom: 25px;

        .update-header {
            display: flex;
            align-items: center;
            padding-bottom: 20px;
            border-bottom: 1px solid #093b5d;
            margin-bottom: 25px;

            h3 {
                font-size: 21px;
                margin-right: auto;
            }

            p {
                opacity: 0.5;
            }
        }

        ul {
            padding-left: 15px;
            margin-top: 10px;
            line-height: 30px;
        }
    }
}