@mixin centerPadding {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px;
}

.landing-page {
    @include centerPadding();
    height: 100%;
    overflow: auto;
    // background: radial-gradient(34.04% 85.76% at 13.49% -.76%, #ed6cd8 -81%, #1b2944 100%);

    .logo-wrapper {
        position: relative;

        .beta-tag {
            position: absolute;
            top: -7px;
            right: -34px;
            color: #ffffff;
            opacity: 1;
            font-size: 12px;
            font-weight: 700;
        }
    }

    .loader {
        background: rgba(255, 255, 255, 0.02);
        display: flex;
        border-radius: 7px;
        align-items: center;
        justify-content: center;
        padding: 100px 50px;
    }

    .page-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: none;
        margin: 40px 0 50px;

        .logo {
            margin-bottom: 50px;
        }

        .sub-title {
            margin-top: 20px;
        }
    }

    .content {
        max-width: 900px;
        margin: auto;
        width: 100%;

        .logo {
            width: 100%;
            max-width: 200px;
        }
    }

    .footer {
        @include centerPadding();
        align-items: center;
        text-align: center;

        p {
            margin-top: 30px;
        }

        .social-icons {
            list-style: none;
            margin: 0;
            display: flex;

            li a {
                display: block;
                padding: 5px;
                margin: 5px 10px;

                &:hover svg {
                    fill: #FC822F;
                }
            }
        }
    }
}