.chakra-menu__menu-button.main-menu-dropdown,
body.home-page #header .chakra-menu__menu-button.main-menu-dropdown, .main-menu-dropdown {
  background: none !important;
  font-weight: 500 !important;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
}

.div-signout {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.host-an-event {
  font-weight: bold;
}

.logoutbtnb {
  padding-left: 0.5rem;
  padding-right: 0.7rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 6px;
}

.profile-button>span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 700;

  .chakra-avatar {
    width: 30px;
    height: 30px;
  }

  .balance {
    position: relative;
    top: 1px;
  }
}


#nav-bar {
  width: 100%;
  top: 0;
  height: 92px;
  margin-bottom: 10px;
   position: fixed;
  z-index: 999;



  .nav-content {
    .logo {
      position: relative;
      top: 1px;
      max-width: 120px;
    }

    .beta-tag {
      position: absolute;
      top: -6px;
      right: 4px;
      color: #ffffff;
      opacity: 1;
      font-size: 11px;
      font-weight: 700;
    }
  }

  &.fixed {
    background: rgb(101 101 101 / 9%) !important;
    border: 1px solid rgb(102 100 100 / 13%);
  }

  .main-nav {
    margin: 0 25px 0;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
  }
}

@media only screen and (max-width: 1035px) {
  #nav-bar {
    .main-nav {
      margin: 0 auto;
      justify-content: center;
    }
  }
}