.main-menu-content {
    z-index: 999999;

    .main-menu {
        list-style: none;
        display: flex;
        margin-left: auto;
        align-items: center;
        justify-content: center;

        .menu-item,
        a:link {
            padding: 10px;
            margin: 0 10px;
            text-align: center;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .profile {
        align-items: center;
        justify-content: center;
        margin-left: 10px;

        .connect {
            background: $neo-gradiant;
            padding: 13px 15px;
        }
    }
}