#create-room {
  .settings-wrapper {
    padding: 30px;
    margin: 35px 0;

    h4 {
      display: flex;
      font-size: 18px;
      border-bottom: 1px solid #0d344d;
      padding-bottom: 20px;
      margin-bottom: 20px;

      .tag {
        margin-left: auto;
      }
    }

    .type-selector {
      padding: 10px 0;
      display: flex;
      align-items: center;

      .item {
        cursor: pointer;
        width: 33.3%;
        background: #052538;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 30px;
        margin: 10px;
        border-radius: 8px;
        position: relative;
        border: 2px solid transparent;

        &.coming-soon {
          opacity: 0.5;
        }

        .label {
          font-size: 12px;
          opacity: 0.5;
          position: absolute;
          bottom: 20px;
        }

        svg {
          margin-bottom: 20px;
          width: 60px;
          height: 60px;
          color: #426175;
        }

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }

        .text {
          margin-bottom: 15px;
        }
      }

      .item-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 1px;
        opacity: 0;

        &:checked + .item {
          border-color: #41a6e6;
          background: #062a41;
        }
      }
    }

    &.highlight {
      border: 2px dashed #306e98;
      background: #092538;
    }

    .chakra-form-control {
      margin: 25px 0;

      &:first-child {
        // margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.sidebyside {
        margin-bottom: 0px;
        margin-top: 0px;
      }

      .chakra-form__label {
        margin-bottom: 0;
        font-size: 15px;
        opacity: 0.6;
      }
    }
  }
}
