$bar-height: 70px;

#admin-controls {
    display: flex;
    background: #2d2c73;
    height: $bar-height;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;

    h2 {
        font-size: 17px;
        font-weight: 700;
        margin-right: 15px;
    }

    .button-wrap {
        display: flex;

        button {
            margin: 0 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

body.admin-active #container {
    height: calc(100% - #{$bar-height});
}

@media only screen and (max-width: 677px) {
    #admin-controls {
        h2 {
            font-size: 14px;
        }

        .button-wrap {
            button {
                padding: 5px 10px;
                font-size: 13px;
                width: auto;
                margin: 0 5px;

                svg {
                    display: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    #admin-controls {
        overflow: hidden;
        overflow-x: auto;
        padding: 5px;

        h2 {
            display: none;
        }

        .button-wrap {
            justify-content: space-between;
            width: 100%;
            margin: 0;

            button:last-child {
                margin-right: 5px;
            }
        }
    }
}