#smart-auctions {
    width: 100%;
    // margin: 150px 0;

    .smart-auctions-header {
        font-size: 24px;
        padding-bottom: 15px;
        margin-bottom: 15px;
        font-weight: 700;
        color: #fff;
        width: 100%;
        display: flex;
        align-items: center;

        h3 {
            margin-right: auto;
            position: relative;
            top: 10px;
        }

        .select-wrap {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid rgba(255, 255, 255, 0.58);
            }

            select {
                border: 1px solid rgba(255, 255, 255, 0.48);
                appearance: none;
                padding: 9px 9px 8px 13px;
                min-width: 170px;
                border-radius: 5px;
                background: transparent;
                color: #fff;
                font-size: 16px;
                font-weight: 700;
                outline: none;
                cursor: pointer;
            }

            option,
            option:checked {
                color: #000;
            }

            .group-switch {

                font-size: small;
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }

    .no-auctions {
        padding: 90px 40px;
        border-radius: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.01);
        width: 100%;
    }

    .auction-grid {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(3, 1fr);

        .auction-item {
            color: #fff;
            display: flex;
            border-radius: 9px;
            flex-direction: column;

            border: 1px solid rgb(102 100 100 / 13%);
            position: relative;

            .image {
                border-radius: 9px 9px 0 0;
                background: rgba(255, 255, 255, 0.01);
                height: 170px;
                width: 100%;
                flex-grow: 1;
                overflow: hidden;
                position: relative;

                img {
                    position: absolute;
                    top: -50%;
                    left: 0;
                }
            }

            .item-footer {
                padding: 15px;
                background: rgba(255, 255, 255, 0.04);
                margin-top: auto;
            }

            .time-wrapper {
                position: absolute;
                top: 15px;
                right: 15px;
                //background-color: #ed6cd8;
            }

            #starti {
                color: #ffffff;
            }



            &.empty {
                border-color: transparent;
                background: rgba(255, 255, 255, 0.008);
            }

            .auction-info {
                h3 {
                    font-size: 18px;
                    font-weight: 700;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.11);
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                }

                .auction-footer {
                    padding-top: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    svg {
                        width: 30px;
                        opacity: 0.2;
                        height: 30px;
                    }

                    .button {
                        min-width: 100px;
                        padding: 9px 9px 8px;
                    }
                }
            }
        }
    }
}






.time-wrapper {
    font-size: 13px;
    background-color: #062438;
    color: black;
    padding: 8px 5px 5px;
    display: flex;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
        margin-bottom: 3px;
        opacity: 1;
    }

    .timer {
        display: inline-flex;
        color: #fff;
        align-items: center;
        justify-content: center;

        .timer-item {
            .val {
                color: white;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 0 4px;
                font-size: 13px;
                font-weight: 700;
            }
        }
    }
}



.auction-list {
    width: 100%;

    .auction-list-item {
        display: flex;
        align-items: center;
        border-radius: 6px;
        background: rgba(255, 255, 255, 0.04);
        border: 1px solid rgb(102 100 100 / 13%);
        padding: 10px;
        margin: 20px 0;

        .tag {
            margin-right: 10px;
        }

        #start {
            color: white;
        }

        .time-wrapper {
            margin-right: 10px;
            flex-direction: row;
            background: rgba(255, 255, 255, 0.04);
            padding: 8px 7px 5px 10px;



            p {
                margin: 0px 5px 0 0;
                font-size: 0.9rem;
            }

            .timer {
                .timer-item {
                    .val {
                        color: #fff;
                    }
                }
            }
        }

        h3 {
            font-size: 18px;
            font-weight: 700;
            color: #fff;
            position: relative;
            top: 1px;
            margin-right: auto;
        }

        .button {
            min-width: 100px;
        }

        svg {
            width: 30px;
            opacity: 0.5;
            height: 30px;
            margin-left: 10px;
            margin-right: 25px;
        }
    }
}

@media only screen and (max-width: 852px) {
    #smart-auctions {
        .auction-grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media only screen and (max-width: 648px) {
    #smart-auctions {
        .auction-list {
            .auction-list-item {
                flex-direction: column;
                justify-content: center;

                .time-wrapper {
                    margin: 20px 0 0;
                }

                h3 {
                    margin: 20px 0 0;
                }

                .button {
                    margin: 30px 0 10px;
                }

                svg {
                    margin: 20px 0;
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
}

@media only screen and (max-width: 584px) {
    #smart-auctions {
        .auction-grid {
            grid-template-columns: repeat(1, 1fr);

            .auction-item {
                &.empty {
                    display: none;
                }
            }
        }
    }
}


@media only screen and (max-width: 426px) {
    #smart-auctions {
        .auction-list {
            .auction-list-item {
                flex-direction: column;
                justify-content: center;

                h3 {
                    text-align: center;
                    padding: 0 15px;
                }

                .button {
                    width: 90%;
                }
            }
        }

        .smart-auctions-header {
            justify-content: space-between;
            align-items: center;

            h3 {
                top: 0;
                margin: 0;
            }
        }
    }
}