.featured-items {
    position: relative;
    padding: 20px 0;
    background: #051a28;
    margin: 2px;
    border-radius: 5px;

    .slick-arrow {
        display: none !important;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -2px;
        border-radius: 6px;
        background-image: linear-gradient(45deg, #fc4bdb, #735ffe);
    }

    &:after {
        content: "";
        position: absolute;
        top: -10px;
        height: 20px;
        width: 125px;
        left: 10px;
        z-index: 1;
        background: #051a28;
    }

    h2 {
        -webkit-background-clip: text;
        background-image: linear-gradient(to right, #fc4bdb, #735ffe);
        -webkit-text-fill-color: transparent;
        color: #fc4bdb;
        position: absolute;
        top: -12px;
        left: 16px;
        z-index: 2;
    }

    .refresh-button {
        position: absolute;
        top: -11px;
        right: 14px;
        animation: fadein 300ms ease-in;
        cursor: pointer;
        background: #051a28;
        padding: 0 10px;

        svg {
            width: 20px;
            height: 20px;
            color: #3c5e72;
        }

        &:hover {
            svg {
                color: #fff;
            }
        }
    }

    .grid-view {
        position: relative;
        z-index: 2;
        overflow: hidden;

        .grid-view-wrapper {
            display: block;
            grid-gap: 0;

            &.normal {
                display: flex;
                flex-wrap: wrap;

                .grid-item {
                    transform: none;
                }
            }

            .grid-item {
                background: none;
                width: 215px;
                margin: 0 10px;
                transform: translateX(10px);

                .contents {
                    border-radius: 13px;

                    .details {
                        opacity: 0;
                        display: flex;
                        position: absolute;
                        border-radius: 13px;
                        height: 100%;
                        flex-direction: column;
                        background-color: rgba(5, 37, 60, 0);
                        transition: all 190ms ease-out;

                        .nft-details {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-grow: 1;
                            flex-direction: column;
                        }
                    }

                    .image-wrapper {
                        border-radius: 13px;
                        image-rendering: pixelated;
                        image-rendering: -moz-crisp-edges;
                        image-rendering: crisp-edges;
                    }
                }

                &:hover {
                    .contents {
                        .details {
                            background-color: rgba(5, 37, 60, 1);
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.slick-list,
.slick-slider,
.slick-track {
    position: relative;
    display: block
}

.slick-loading .slick-slide,
.slick-loading .slick-track {
    visibility: hidden
}

.slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: 0
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.slick-track {
    top: 0;
    left: 0
}

.slick-track:after,
.slick-track:before {
    display: table;
    content: ''
}

.slick-track:after {
    clear: both
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px
}

[dir=rtl] .slick-slide {
    float: right
}

.slick-slide img {
    display: block;
    border-radius:50%;
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slick-arrow.slick-hidden {
    display: none
}

@media only screen and (max-width: 814px) {
    .featured-items {
        .grid-view {
            .grid-view-wrapper.normal {
                justify-content: center;
            }
        }
    }
}

@media only screen and (max-width: 387px) {
    .featured-items {
        .grid-view {
            .grid-view-wrapper {
                .grid-item {
                    width: 233px;
                    height: 343px;

                    .contents {
                        .details {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}