 
.homefooter {
  width: 100%;
  margin: 0 auto;
  max-width: 1300px;
}
  

  .social-links {
    margin-left: auto;
    display: flex;
  }

  .social-links li {
    padding: 0 10px;
  }

  .social-links a {
    text-decoration: none;
    margin: 0 5px;
    display: block;
  }

  .social-links a:last-child {
    margin-right: 0;
  }

  .social-links svg {
    width: 30px;
    height: 30px;
  }

  .social-links a:hover svg {
    color: #fc4adb;
  }

  .aiheader {
    font-size: 1.2rem;
  }

  .social-links li:last-child {
    padding-right: 0;
  }

  #hero-section {
    color: #fff;
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 50px 0 100px;
  }

  #hero-section .trade-example {
    width: 100%;
    max-width: 550px;
    margin-left: auto;
  }

  #hero-section .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #hero-section h1,
  #hero-section h2 {
    margin: 0;
    padding: 0;
  }

  #hero-section h1 {
    flex-direction: column;
    font-size: 47px;
    line-height: 1.2em;
    width: 100%;
  }

  #hero-section h2 {
    font-size: 20px;
    line-height: 1.2em;
    width: 100%;
    margin-top: 20px;
    opacity: 0.5;
  }

  #app-links {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    color: #fff;
  }

  #app-links ul {
    width: 100%;
    padding: 30px 0 40px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 800px;
  }

  #app-links a {
    text-decoration: none;
    background: rgb(255 255 255 / 4%);
    border: 1px solid rgb(255 255 255 / 10%);
    display: flex;
    align-items: center;
    padding: 30px 30px 30px 20px;
    margin: 20px 0;
    cursor: pointer;
    border-radius: 6px;
  }

  #app-links li svg {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }

  #app-links .button {
    margin-left: auto;
  }

  #app-links a:first-child {
    margin-top: 0;
  }

  #app-links .name {
    display: flex;
    flex-direction: column;
    font-size: 19px;
  }

  #app-links .sub-title {
    opacity: 0.5;
    margin-top: 9px;
    font-size: 14px;
  }

  #footer {
    position: relative;
   
  }

  #footer a {
    text-decoration: none;
    height: 0.5rem;
  }

  #footer a:hover {
    text-decoration: underline;
  }

  #footer .sep {
    padding: 0 8px;
  }

  .social-links.footer {
    display: flex;
  }

  .socials {
    font-size: 2px;

    height: 3px;
  }

  .homefooter {
    display: flex;
    justify-content: space-between;
  
  }
 

  #line {
    margin-left: 10px;
    margin-right: 10px;
  }

  .footerlist {
    display: flex;
    margin-bottom: 1rem;
    flex-direction: row;
    justify-content: space-between;

    width: 13%;
    font-size: 1.5rem;

    :hover {
      color: #fc822f;
    }
  }

  #scroll-to {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
  }

  #scroll-to svg {
    margin-top: 20px;
    width: 30px;
    height: 30px;
  }

  .button {
    background: #fc822f;
    display: inline-flex;
    align-items: center;
    padding: 12px;
    border-radius: 6px;
    font-size: 15px;
    text-align: center;
  }

  .button:hover {
    background: #fc822f;
  }
 

  @media only screen and (max-height: 1106px) {
    #hero-section {
      margin: 30px 0 60px;
    }

    #hero-section .trade-example {
      max-width: 350px;
    }
  }

  @media only screen and (max-width: 1106px) {
    #hero-section .trade-example {
      max-width: 400px;
    }
  }

  @media only screen and (max-width: 913px) {
 

    #nav-bar .hamburger-menu {
      top: -10px;
      right: 0;
    }
  }

  @media only screen and (max-width: 855px) {
    #hero-section {
      margin-top: 20px;
      flex-direction: column-reverse;
      text-align: center;
      justify-content: center;
      align-items: center;
    }

    #hero-section .trade-example {
      margin: 0 0 50px 0;
    }
  }

  @media only screen and (max-width: 777px) {
    #video-section {
      margin: 0 auto 60px 0;
    }

    #video-section p {
      margin: 10px 0 20px;
    }

    #video {
      height: auto;
      margin-top: 0;
    }

    #video img {
      width: 100%;
      height: auto;
      position: relative;
    }

    .homefooter {
      display: block;
      display: flex;
      padding: 0;
      margin-bottom: 1rem;
      flex-direction: column-reverse;
      align-items: center;

      h1 {
        text-align: center;
        font-size: 0.8rem;
      }

      
      .footerlist {
        margin-bottom: 1rem;
   
        margin-top: 2rem;
        font-size: 1.3rem;
      }
    }
  }

  @media only screen and (max-width: 540px) {
    #hero-section h1 {
      font-size: 30px;
    }

    #container {
      // padding: 30px 30px 0;
    }


    #nav-bar .hamburger-menu {
      top: 10px;
      right: 0;
    }

    #footer p {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    #footer a {
      padding-top: 20px;
    }

    #footer .sep {
      display: none;
    }

    .homefooter {
      display: block;
      display: flex;
      padding: 0;
      margin-bottom: 1rem;
      flex-direction: column-reverse;
      align-items: center;

      h1 {
        text-align: center;
        font-size: 0.8rem;
      }

      .footerlist {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        width: 50%;
        margin-top: 2rem;
        font-size: 1.3rem;
      }
    }
  }

  @media only screen and (max-width: 605px) {
    #app-links-list a {
      padding: 15px;
    }

    #app-links li svg {
      width: 30px;
      height: 30px;
      margin-right: 15px;
    }

    .homefooter {
      display: block;
      display: flex;
      padding: 0;
      margin-bottom: 1rem;
      flex-direction: column-reverse;
      align-items: center;

      h1 {
        text-align: center;
        font-size: 0.8rem;
      }

      .footerlist {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        width: 50%;
        margin-top: 2rem;
        font-size: 1.3rem;
      }
    }
  }

  @media only screen and (max-width: 529px) {
    #app-links-list a {
      padding: 15px;
    }

    #app-links li svg {
      width: 30px;
      height: 30px;
      margin-right: 15px;
    }

    .homefooter {
      display: block;
      display: flex;
      padding: 0;
      margin-bottom: 1rem;
      flex-direction: column-reverse;
      align-items: center;

      h1 {
        text-align: center;
        font-size: 0.8rem;
      }

      .footerlist {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        width: 50%;
        margin-top: 2rem;
        font-size: 1.3rem;
      }
    }
  }

  @media only screen and (max-width: 416px) {
    #app-links a {
      padding: 20px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    #app-links a .button {
      margin: 20px 0 0 0;
    }

    #app-links a svg {
      width: 40px;
      height: 40px;
      margin: 0 0 20px 0;
    }

    #header {
      justify-content: center;
    }

    #header .social-links {
      display: none;
    }

    .social-links.footer {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    #app-links svg {
      width: 30px;
      height: 30px;
      margin-right: 20px;
    }

    .homefooter {
      display: block;
      display: flex;
      padding: 0;
      margin-bottom: 1rem;
      flex-direction: column-reverse;
      align-items: center;

      h1 {
        text-align: center;
        font-size: 0.8rem;
      }

      .footerlist {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        width: 50%;
        margin-top: 2rem;
        font-size: 1.3rem;
      }
    }
  }
