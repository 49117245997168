.section-pills {
    list-style: none;
    display: flex;
    margin-top: 40px;

    a {
        background: rgba(255, 255, 255, 0.08);
        align-items: center;
        justify-content: center;
        font-size: 14px;
        padding: 5px 15px 4px;
        margin: 0 10px;
        display: flex;
        border-radius: 100px;
        text-align: center;

        &:hover {
            background: rgba(255, 255, 255, 0.2);
        }
    }
}

.faq {
    line-height: 1.6;

    .faq-section {
        padding: 30px;
        margin-bottom: 20px;

        h1 {
            font-size: 28px;
            margin-bottom: 30px;
        }

        .faq-item {
            margin-bottom: 30px;

            a:visited,
            a:link {
                color: hotpink;
            }

            h2 {
                font-size: 17px;
                margin-bottom: 5px;
            }

            p {
                opacity: 0.8;
            }
        }
    }
}