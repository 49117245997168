#chakra-modal-swap-modal {
    header {
        padding-top: 25px;
    }

    .chakra-modal__footer {
        padding-top: 0 !important;
    }
}

.swap-content {
    position: relative;

    ul {
        margin-left: 20px;
    }

    code {
        word-break: break-word;
    }

    a,
    a:link {
        color: #FC822F !important;
    }

    .copy-btn {
        border: 1px solid white;
        border-radius: 10px;
        padding: 5px;
        margin-left: 10px;
        font-size: 10px;
    }

    .swap-item {
        border-radius: 8px;
        background: #3a475c;
        padding: 20px;

        h2 {
            font-size: 16px;
            border-bottom: 1px solid #54637c;
            margin-bottom: 17px;
            padding-bottom: 17px;
        }

        .message {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 140px;

            svg {
                width: 50px;
                height: 50px;
                margin-bottom: 25px;
                color: #647591;
            }

            .content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }

        .action-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;

            .controls {
                margin-left: 20px;

                .button {
                    min-width: 120px;
                }
            }
        }

        .form {
            display: flex;
            margin-top: 20px;

            input {
                margin-top: 20px;
                background: #fff !important;
                color: #000 !important;

                &::placeholder {
                    color: #838383 !important;
                    opacity: 1;
                }
            }

            .controls {
                min-width: 150px;
                margin-left: 20px;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;

                .button {
                    min-width: 120px;
                }
            }
        }
    }

    .swap-loader {
        position: absolute;
        z-index: 9999;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;

        h2 {
            font-size: 22px;
            margin-bottom: 11px;
        }

        p {
            font-size: 15px;
        }
    }
}