#modal {
  background-color: #39455b;
  padding-left: 3rem;
  padding-right: 3rem;
}

.modal {
  overflow-y: auto;
}

.bigmodaldiv {
  display: flex;
  justify-content: space-between;
}

#image {
  width: 100%;
  border-radius: 7px;
}

.nftimagediv {
  width: 43%;
}

.detailscol {
  background-color: #485570;
  margin-top: 1rem;
  border-radius: 7px;
}

#colctdetails {
  margin-left: 6px;
  font-size: 0.75rem;
}

.flexItems {
  margin-top: 5px;
  width: fit-content;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #53607c;
  gap: 5px;
  border-radius: 5px;

  h1 {
    font-size: 0.85rem;
  }
}

.collection-names {
  width: 53%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nftnamediv {
  height: 20%;
  background-color: #485570;
  border-radius: 7px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.nft-detailsm {
  height: 77%;
  background-color: #485570;
  border-radius: 7px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.nftname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 6rem;
}

.titleAtributes {
  font-weight: bold;
  margin-top: 1rem;
}

.modalclose {
  color: white;
}

.title {
  font-weight: 700;
  font-size: 1rem;
  margin-top: 1rem;
}

#nftimage {
  width: 30%;
}

.title-collection {
  color: #fb4bdb;
}

.nft-card-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 6rem;
}

.ntfname {
  font-size: 2rem;
  font-weight: 700;
  margin-top: rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.link {
  color: #ec4cd2;
  font-size: 0.7rem;
  font-weight: 500;
  text-decoration: underline;
  text-align: center;
}

.link-blue {
  color: #fc822f;
  text-decoration: underline;
  text-align: center;
}

.ntfimage:hover {
  cursor: pointer;
  opacity: 0.3;
  transition: ease-in;
}

.lock-icon {
  position: absolute;
  top: 40%;
  right: 42%;
  z-index: 1;
  font-size: 2.5rem;
}

.collection-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// .flex-container {
//     display: flex;
//     flex-direction: row;
//     text-align: center;
//     width: fit-content;
//     background-color: red;
//     // grid-gap: 10px;
//     // flex-flow: wrap;
//     // justify-items: left;
// }

.flex-item {
  background-color: #485570;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  padding: 8px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 0.85rem;
  }

  p {
    font-size: 0.5rem;
  }
}

.split {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.detailsp {
  font-size: 0.75rem;
}

// #chakra-modal-modal {

//     .chakra-modal__body {
//         padding: 0 ;
//         padding-top: 0px;
//         padding-bottom: 0px;
//     }
// }
.locking-nft-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .mark {
    margin-left: 2rem;
  }
}

@media screen and (max-width: 480px) {
  .bigmodaldiv {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .nftmodal {
    display: block;
    width: fit-content;
    margin: auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .collection-names {
    width: 100%;
  }

  .nft-details {
    margin-top: 10px;
  }

  .nftimagediv {
    width: 100%;
    justify-content: start;
    gap: 10px;
  }

  .detailsdiv {
    width: 100%;
    padding: 0;
    margin-top: 0;
  }

  .collection-details {
    display: flex;
    flex-direction: column;
  }

  .split {
    width: 100%;
    text-align: start;
    gap: 0px;
  }

  .imgdiv {
    width: 45%;
    margin-top: 2.2rem;
  }

  .ntfname {
    font-size: 1.5rem;
    font-weight: 700;
    align-items: start;
    text-align: start;
  }

  .title {
    margin-top: 0.6rem;
  }

  .nft-detailsm {
    margin-top: 0.7rem;
  }

  .nftinfo {
    margin-bottom: 1rem;
  }

  .flex-container {
    gap: 15px;
  }

  .lock-icon {
    position: absolute;
    top: 40%;
    right: 38%;
    z-index: 1;
    font-size: 1.5rem;
  }

  .locking-nft-div {
    display: flex;
    flex-direction: column;
  }
}
