#toggle {
  position: fixed;
  top: -1px;
  left: -1px;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.hamburger-menu {
  display: none;
}

@media only screen and (max-width: 1035px) {
  #page {
    padding-top: 0;
  }

  #nav-bar {
    &.fixed {
      background: none;
    }

    position: relative;
    height: auto;
    padding-bottom: 0;
    margin-bottom: 40px;

    .logo {
      position: relative;
    }

    .profile {
      position: relative;
      margin-left: auto;
      right: 0;
    }

    .nav-content {
      flex-wrap: wrap;
    }

    .main-nav {
      position: relative;
      z-index: 99;
      display: flex;
      align-items: center;
      order: 3;
      width: 100%;
      height: 60px;
    }
  }
}

@media only screen and (max-width: 540px) {
  #nav-bar {
    margin-bottom: 30px;

    .nav-content {
      align-items: center;
      margin-bottom: 0;

      .beta-tag {
     right: 10px;
      }

      .profile {
        display: none;
        @include box();

        margin: 0 auto;
        width: calc(100% - 40px);
        justify-content: space-between;
        left: 0;
        right: 0;
        font-size: 15px;

        .chakra-spinner {
          margin: 10px auto;
        }

        .connect-button {
          margin: 10px auto;
          padding: 20px 23px;
        }
      }

      .avatar-menu-holder {
        width: 100%;
        flex-direction: column;
        gap: 20px;
      }

      .main-nav {
        display: none;
        flex-direction: column;
        height: auto;

        &.open {
          display: initial;
        }

        a:link {
          border-radius: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 3px 0;
          padding: 15px;
        }
      }
    }

    .hamburger-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      cursor: pointer;
      opacity: 0.7;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  #toggle:checked~.hamburger-menu {
    opacity: 1;
    background: #062e49;
    border-radius: 3px;
  }

  #toggle:checked~.main-nav {
    display: block;
  }

  .page-width {
    padding: 0 20px;
  }
}