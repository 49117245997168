body,
html {
  margin: 0;
  height: 100%;
  padding: 0;
}

body {
  background: #99D6E9 !important;
  overscroll-behavior-y: none;
  // font-family: InterRegular, sans-serif !important;
  text-shadow: 0px 0px 1px rgb(0 0 0 / 34%);
}

// Chakra overrides
body,
.grid-view-wrapper .chakra-text {
  color: #fff !important;
}

#chakra-toast-manager-bottom {
  z-index: 9999999 !important;
  bottom: 20px !important;
}

#chakra-toast-manager-top {
  z-index: 9999999 !important;
}

.chakra-menu__menu-list,
.chakra-menu__menu-button {
  // background: rgb(10 10 10 / 93%) !important;
  border: 1px solid rgb(102 100 100 / 13%) !important;

  // Resetting styles for all child elements
  & * {
    background: none !important; // Reset background
    border: none !important; // Reset border
  }
}

.chakra-button {
  transition: none !important;
}

// End Chakra overrides

#container,
#root {
  height: 100%;
}

.page-header {
  border-bottom: 1px solid #0e2b3f;
  padding-bottom: 15px;
  margin-bottom: 29px;
  font-size: 1.25rem;
}

.chakra-modal__content {
  margin: 15px;
}

.chakra-modal__overlay {
  background: rgba(0, 0, 0, 0.7) !important;
}

#userback_button_container {
  .userback-button {
    z-index: 9999999 !important;
  }
}

.refresh-balance {
  animation: fadein 300ms ease-in;
  cursor: pointer;
  background: #051a28;
  padding: 0 10px;

  svg {
    width: 20px;
    height: 20px;
    color: #3c5e72;
  }

  &:hover {
    svg {
      color: #fff;
    }
  }
}

@import './footer';
@import './page';

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #444;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lighten(#313131, 10%);
}
