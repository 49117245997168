.new-number-input {
  position: relative;

  .number-input {
    border-radius: 0px;
    height: 32px;
    background: #323e58;
    padding-right: 60px;
    padding-left: 7px;
  }

  .stepper-controls {
    border: 0px;
  }

  .inline-addon {
    position: absolute;
    z-index: 9;
    right: 25px;
    top: 4px;
  }
}

.check-bids-message {
  background: #b87124;
  padding: 9px 9px 7px;
  font-size: 16px;
  border-radius: 4px;
  color: #fff;
  margin-left: 47px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -4px;

  .icon-arrow {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 13px;
    left: -38px;
    transform: rotate(20deg);
    color: #144261;
  }

  .icon {
    margin: 0 10px 0 4px;
    display: inline;
    position: relative;
    width: 20px;
    height: 20px;
    top: -2px;
  }
}

.collection-table {
  .image-wrap {
    position: relative;

    .locked-overlay {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.72);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
}

.bid-modal-btn {
  color: white;
}

.locked-modal {
  text-align: center;
  padding: 30px;

  svg {
    opacity: 0.5;
    width: 40px;
    height: 40px;
    margin-bottom: 30px;
  }

  hr {
    margin: 30px 0;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
  }

  .button {
    min-width: 100px;
  }
}

.listing-page {
  display: flex;
  align-items: flex-start !important;
  flex-direction: column !important;

  .listings-table-wrap {
    margin-left: 0;
  }

  .listing-section {
    width: 100%;
    min-height: 79px;
  }

  .small-link {
    margin-left: 20px;
    padding: 2px;
    position: relative;
    top: -1px;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    color: #fc822f;
  }
}

.ns-modal {
  .ns-dialog-content {
    background: #091824;

    .chakra-modal__close-btn {
      top: 40px;
    }
  }
}

#chakra-modal-listing-modal {
  background: #091824;
  border-radius: 0px;

  .listing-modal-header {
    margin-top: 7px;
    padding: 16px 21px 1px 21px;

    p {
      font-size: 16px;
    }
  }

  .bid-modal-pass-btn {
    z-index: 9;
    background: #fff;
    color: #081923;
    text-transform: uppercase;
    font-weight: bold;
    width: 120px;
  }

  .bidding-modal.tabs-wrapper {
    margin-left: 30px;

    @media screen and (max-width: 870px) {
      margin: 0px;
      width: 100%;
    }
  }

  .listing-modal-body {
    display: flex;
    position: relative;
    padding: 0px 32px;
    justify-content: space-between;
    gap: 10px;

    &.wip {
      padding: 32px;
    }

    @media screen and (max-width: 870px) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }

    .image-arrows {
      width: 490px;
      gap: 12px;
      position: relative;

      @media screen and (max-width: 611px) {
        width: 100%;
      }

      .asset-container {
        &.textitem {
          flex-grow: 1;
        }

        position: relative;

        &.is-listed {
          border: solid 8px #fc822f;
        }

        .is-listed-check {
          position: absolute;
          bottom: 20px;
          right: 20px;
        }
      }
    }

    img.chakra-image__placeholder {
      max-width: 100%;
      height: fit-content;
    }

    .tabs-wrapper {
      .footer-buttons {
        margin-top: 15px;

        button {
          &.solid-blue {
            background: #fc822f;
            text-transform: uppercase;
          }
        }
      }

      .tabs-buttons {
        margin-bottom: -1px;

        .tab {
          background: transparent;
          display: flex;
          align-items: center;
          justify-content: center;

          &.active {
            background: #052032;
            // border-top: solid 1px #fff;
            // border-right: solid 1px #fff;
            // border-left: solid 1px #fff;
            // border-bottom: 0px;
          }

          .tag {
            position: unset;
            background: #ff4adb;
          }
        }
      }
    }

    .confirm-message {
      flex-grow: 1;
      display: flex;
      align-items: center;
      background: #39455b;
      justify-content: center;
      flex-direction: column;
      font-weight: 700;

      .button-wrap {
        display: flex;
        margin-top: 30px;
      }
    }

    img {
      width: 100%;
      image-rendering: pixelated;
      image-rendering: -moz-crisp-edges;
      image-rendering: crisp-edges;
    }

    h4 {
      font-size: 25px;
      font-weight: 700;
    }

    border: 5px solid transparent;
    min-height: 352px;

    .listed-tag {
      background: #39961a;
      padding: 4px 7px;
      font-size: 13px;
      font-weight: 800;
      border-radius: 50px;
      min-width: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: fit-content;
    }

    .listing-modal-details {
      padding: 18px;
      background: #052032;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      //border: solid 1px #fff;
      min-height: 350px;

      .ns-title-and-status {
        display: flex;
        align-items: center;
        margin-bottom: 7px;
        gap: 10px;
        flex-wrap: wrap;

        .ns-modal-bid-info {
          background: #1e4057;
          border-radius: 18px;
          padding: 0px 20px 0px 10px;
          font-size: 15px;
          display: flex;
          align-items: center;
          gap: 7px;

          &.other {
            padding: 0px 10px 0px 10px;
            background: #fc822f;
            text-align: center;
          }

          &.tied {
            padding: 0px 10px 0px 10px;
          }

          span {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #40cd56;
            display: inline-block;
            margin-bottom: 1px;
          }
        }
      }

      .edit-link {
        color: #fc822f;
        margin-left: auto;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .nft-details-tab {
        h4 {
          font-size: 16px;
          line-height: 19px;
          color: #fc822f;
        }

        h3 {
          font-weight: 700;
          font-size: 25px;
          color: #ffffff;
        }

        h5 {
          font-weight: 700;
          font-size: 16px;
          color: #ffffff;
          margin-top: 18px;
          margin-bottom: 6px;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }
      }
    }

    .custom-tooltip {
      max-width: 300px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      word-wrap: break-word;
      white-space: pre-wrap;
      z-index: 99;
    }

    .custom-tooltip-arrow {

      transform: rotate(134deg) !important;
    }

    .listing-modal-inputs {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .chakra-input__group.ns-set-your-bid {
        margin-top: 4px;
        margin-bottom: 5px;
        width: unset;
      }

      .stats {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .set-bid-holder {
          display: flex;
          gap: 10px;
          align-items: center;

          .remove-bid {
            cursor: pointer;
          }
        }

        .stat {
          background: #323e58;
          display: flex;
          justify-content: space-between;
          padding: 7px 12px;
          font-size: 14px;
          font-weight: 700;
          white-space: nowrap;

          .stat-details {
            display: flex;
            flex-direction: column;
          }

          .button {
            background: transparent;
            border: 1px solid #fc822f;
            border-radius: 6px;
          }

          &.highlight {
            background: #647596;
          }

          .label {
            font-size: 12px;
            font-weight: 400;
          }
        }
      }

      .quick-buttons {
        margin-top: auto;
        padding: 30px 0 0;

        .chakra-button {
          background: #fc822f;
          min-width: unset;

          &:hover {
            background: lighten(#fc822f, 5%);
          }
        }
      }

      .is-disabled {
        opacity: 0.5;
      }

      .chakra-input__group {
        margin-top: 30px;
      }
    }
  }

  .nft-items-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;

    p {
      font-size: 14px;
    }

    .nav-button {
      border-radius: 6px;
      background: transparent;
      justify-content: center;
      gap: 6px;
    }
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 23px 21px 23px;
  }

  .chakra-button {
    min-width: 100px;

    &.primary {
      background: $neo-gradiant;
      width: 100%;
      max-width: 200px;
    }

    &.remove {
      background: #dc3545;
    }
  }
}

.listing-selection {
  flex-grow: 1;

  .icon-button {
    width: 50%;
    height: 30px;

    svg {
      width: 18px;
      height: 18px;
      opacity: 0.6;
    }
  }

  .grid-view-wrapper {
    .grid-item {

      .list-controls,
      .listing-button {
        width: 100%;
      }

      &.selected {
        .contents {
          .list-controls {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 868px) {
  #chakra-modal-listing-modal {
    .listing-modal-body {
      .listed-tag {
        top: 7px;
        right: 7px;
      }

      .listing-modal-inputs {
        .stats {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;

          span {
            margin: 5px 0;
            border-radius: 0;
            padding: 0;
            background: none;
            white-space: normal;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 798px) {
  .check-bids-message {
    display: block;
    margin-left: 0;
    margin-top: 20px;
    padding: 6px 10px 10px;

    .icon {
      top: 4px;
    }

    .icon-arrow {
      display: none;
    }
  }
}

@media only screen and (max-width: 470px) {
  #chakra-modal--body-listing-modal {
    overflow: hidden;
    overflow-y: auto;
    padding: 0;
  }

  #chakra-modal--header-listing-modal {
    padding-bottom: 60px;
    height: 60px;
  }

  #chakra-modal-listing-modal {
    position: fixed;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0;
    overflow-y: auto;

    .modal-footer {
      margin-top: 15px;
      padding: 15px;

      .btn-icon {
        display: inline;
      }

      .btn-text {
        display: none;
      }

      .chakra-button {
        width: 44px;
        min-width: unset;
      }

      .chakra-button.primary {
        width: auto;
        margin: 0;
        min-width: 100px;
      }
    }

    .chakra-button.primary {
      width: auto;
      margin: 0;
    }

    .listing-modal-body {
      padding-top: 0;
      flex-direction: column;
      margin: 0;
      height: calc(100% - 20px);

      &.is-listed {
        border-color: transparent;

        img {
          outline: 5px solid #39961a;
        }
      }

      .listing-modal-inputs .quick-buttons .chakra-button {
        min-width: unset;
        font-size: 14px;
        padding: 16px !important;
        height: 33px;
        margin: 0;
      }

      img {
        margin-top: 0;
      }

      h4 {
        font-size: 20px;
        margin-bottom: 0;
      }

      .listed-tag {
        top: 7px;
        right: 17px;
      }

      .listing-modal-details {
        padding: 15px;
      }

      .confirm-message {
        padding: 20px;

        .chakra-button {
          margin: 0 20px;
        }
      }
    }
  }
}

.the-nft-details {
  background: rgb(102 100 100 / 13%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 12px;
  margin-top: 19px;
  flex-wrap: wrap;
  gap: 10px;

  .nft-details-item {
    p {
      font-weight: 300;
      font-size: 14px;
      color: #ffffff;
    }

    span {
      font-family: InterBold;
      font-weight: 700;
      font-size: 14px;
      color: #ffffff;
    }
  }

  a {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #fc822f;
    width: 85px;
    display: inline-block;
  }

  .last-link {
    a {
      position: relative;

      >div {
        position: absolute;
        right: 10px;
        top: 2px;
      }
    }
  }
}