.tabs-wrapper {
    .tabs-buttons {
        display: flex;

        .tab {
            padding: 10px;
            cursor: pointer;
            min-width: 100px;
            text-align: center;
            position: relative;

            .tag {
                margin-left: 10px;
                position: absolute;
                top: -10px;
                right: -10px;
            }

            &.active {
                background: #3a455b;
            }
        }
    }
}