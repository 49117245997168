$color: #372f55;

#chain-select-button,
#chain-select-mobile {
  display: none;
}

#chain-select-mobile {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.chain-selector {
  display: flex;
  font-size: 14px;
  background: $color;
  border-radius: 8px;
  justify-content: center;
  align-items: center;

  li {
    text-align: center;
    padding: 12px 13px 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      background: lighten($color, 13%);
    }

    &:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-right: 1px solid lighten($color, 10%);
    }

    &:last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:hover {
      background: lighten($color, 13%);
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

@media only screen and (max-width: 473px) {
  #chain-select-button,
  #chain-select-mobile {
    display: block;
  }

  .smart-auctions-header {
    .chain-select-wrapper {
      position: relative;

      .chain-selector {
        position: absolute;
        z-index: 100;
        top: 20px;
        min-width: 200px;
        right: 0;
        display: none;
        width: 100%;
        flex-direction: column;
        margin-top: 25px;

        li {
          width: 100%;
          border-radius: 0;

          &:first-of-type {
            border-right: none;
          }
        }
      }
    }

    #chain-select-mobile:checked + .chain-selector {
      display: flex;
    }
  }
}
